import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { toast } from 'generics/services/globals/v3';
import service from 'generics/api/sales/v2';
import { ContentTitle } from 'components/titles/v2';
import { Flex } from 'components/grids/v2';
import { useFormReducer } from 'generics/functions/globals/reducer';
import { IButton } from 'components/buttons/v2';

import * as stockActions from '../../../store/actions/sales/store_products.actions';

import { ProductStockUpdate } from './ProductStockUpdate';
import { ITEM_HEADER, BUTTON_LABELS } from './constants';

const GItem = ({ route, uri, area }) => {
  const { _id } = useParams();
  const history = useHistory();
  const {
    item,
    set_item,
    on_number_change,
  } = useFormReducer({});
  const [current, set_current] = useState(null);

  const update = () => {
    service.store_products.update(_id, item)
    .then(() => {
      history.push(route.list);
    })
    .catch(toast.api_danger);
  };

  const on_submit = (e) => {
    e.preventDefault();
    update();
  };

  useEffect(() => {
    service.store_products.get_by_id(_id)
    .then(({ data }) => {
      set_current(data);
      set_item(data.store_product);
    })
    .catch(toast.api_danger);
  }, [_id, uri, area, set_item]);

  const render_buttons = () => (
    <IButton label={BUTTON_LABELS.UPDATE} icon="checkmark" submit />
  );

  return (
    <>
      <ContentTitle config={ITEM_HEADER} />
      <Form onSubmit={on_submit} inverted>
        <Form.Field>
          <label>Precio</label>
          <input name="price" placeholder="Precio" type="number" value={item.price} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Cantidad Total</label>
          <input name="amount" placeholder="Cantidad Minima" value={item.amount} onChange={on_number_change} disabled />
        </Form.Field>
        <Form.Field>
          <label>Cantidad Minima</label>
          <input name="min" placeholder="Cantidad Minima" value={item.min} onChange={on_number_change} />
        </Form.Field>
        <Form.Field>
          <label>Cantidad Maxima</label>
          <input name="max" placeholder="Cantidad Maxima" value={item.max} onChange={on_number_change} />
        </Form.Field>
        <Flex.End>
          {render_buttons()}
        </Flex.End>
      </Form>
      {current && <ProductStockUpdate actions={stockActions} current={current} />}
    </>
  );
};

GItem.propTypes = {
  route: PropTypes.any.isRequired,
  uri: PropTypes.shape({
    get: PropTypes.string.isRequired,
    get_by_id: PropTypes.string.isRequired,
    post: PropTypes.string.isRequired,
    put: PropTypes.string.isRequired,
    remove: PropTypes.string.isRequired,
  }).isRequired,
  area: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export { GItem };
