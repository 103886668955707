export const types = {
  LOGIN: 'login',
  LOGOUT: 'logout',
};

const logout = (data) => ({
  type: types.LOGOUT,
  data,
});

const login = (data) => ({
  type: types.LOGIN,
  data,
});

export default { login, logout };
