import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import api from 'generics/api/billings/v1';
import { toast } from 'generics/services/globals/v4';

import { ORDER_TYPES } from '../../sales.constants';
import { CheckoutInvoiceCart } from './invoice/CheckoutInvoiceCart';

const Order = ({ sale }) => {
  const [order, set_order] = useState({
    type: 'delivery',
    counter: 0,
  });
  const find_order = (type) => (ORDER_TYPES.find((o) => o.key === type));

  useEffect(() => {
    api.orders.get_by_id(sale._id)
    .then(({ data }) => {
      set_order(data);
    })
    .catch(toast.api_danger);
  }, [sale]);

  return (
    <div id="checkout-invoice" style={{ width: '300px' }}>
      <div style={{ background: '#ffffff', overflowY: 'auto', color: '#000000', padding: '10px' }}>
        <hr />
        <div style={{ textAlign: 'center' }}>
          <div>
            <b>{find_order(order.type).value}</b>
          </div>
        </div>
        <div>
          <div>
            {`NUMERO: ${order.counter}`}
          </div>
        </div>
        <CheckoutInvoiceCart sale={sale} />
      </div>
    </div>
  );
};

Order.propTypes = {
  sale: PropTypes.any.isRequired,
};

export { Order };
