const calc_products_v2 = (cart) => (
  cart.map((cart_item) => {
    const { product, store_product, sale } = cart_item;
    return {
      product_id: product._id,
      store_product_id: store_product._id,
      amount: sale.amount,
      type: 'kiosk',
      name: product.name,
      original: sale.price_original,
      price: sale.price_sale,
    };
  })
);

export { calc_products_v2 };
