import React, { useState, useReducer, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Header, Grid, Button, Form } from 'semantic-ui-react';

import { toast } from 'generics/services/globals/v3';
import service from 'generics/api/sales/v2';
import { pagination } from 'generics/api/sales/v0/product-stocks.service';

import { formReducer } from '../../utils';

const ProductStockUpdate = ({ current }) => {
  const [loading] = useState(false);
  const [stock] = useState(current.store_product);
  const [store_product] = useState(current.store_product);
  const [query, setQuery] = useState({
    page: 1,
    limit: 30,
  });
  const [stockLogs, setStockLogs] = useState([]);
  const [operation_types] = useState([{
    key: 'in',
    value: 'Entrada',
  }, {
    key: 'out',
    value: 'Salida',
  }]);
  const [form, setFrom] = useState(null);

  const [item, setItem] = useReducer(formReducer, {
    description: 'Compra/Venta regular',
    amount: 10,
    price_buy: store_product.price,
    price_sale: store_product.price,
  });

  const handleSubmit = ({ operation_type }) => {
    item.amount = parseInt(item.amount, 10);
    item.price_buy = parseInt(item.price_buy, 10);
    item.operation_type = operation_type;

    service.store_products.increase(store_product._id, item)
    .then(() => {
      setQuery({
        page: 1,
        limit: 30,
      });
    });
  };

  const loadLogs = () => {
    pagination({
      stock_id: stock._id,
      store_id: stock.store_id,
    }, query)
    .then(({ data }) => {
      if (query.page === 1) {
        setStockLogs([...data]);
      } else {
        setStockLogs([...stockLogs, ...data]);
      }
    })
    .catch(toast.api_danger);
  };

  const getOperation = (op_types, operation) => (
    op_types.find((type) => type.key === operation).value
  );

  const getStockOperation = (op_types, { operation_type, operation }) => {
    const op_type = op_types.find((type) => type.key === operation_type);

    return (
      <>
        <Grid.Column color="teal" width={1}>
          {op_type.key === 'in' ? operation.amount : 0}
        </Grid.Column>
        <Grid.Column color="teal" width={1}>
          {op_type.key === 'out' ? operation.amount : 0}
        </Grid.Column>
        <Grid.Column color="teal" width={1}>
          {Math.round(operation.price_buy * 100) / 100}
        </Grid.Column>
      </>
    );
  };

  const onHandleClick = (e, operation_type) => {
    e.stopPropagation();
    form.handleSubmit({
      operation_type,
    });
  };

  const handleLoadMore = (e) => {
    e.stopPropagation();
    setQuery({
      page: query.page + 1,
      limit: 30,
    });
  };

  const handleChange = (e) => {
    e.stopPropagation();
    setItem({
      name: e.target.name,
      value: e.target.value,
    });
  };

  useEffect(() => {
    loadLogs();
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    setItem({
      name: '_id',
      value: current._id,
    });
  }, [current]);

  return (
    <>
      <Form
        inverted
        loading={loading}
        onSubmit={(e) => { handleSubmit(e); }}
        ref={(ref) => { setFrom(ref); }}
      >
        <Form.Group>
          <Form.Field>
            <label>Cantidad</label>
            <input name="amount" placeholder="Ej. 100 o -100" type="number" value={item.amount} onChange={handleChange} />
          </Form.Field>
          <Form.Field>
            <label>Precio</label>
            <input name="price_buy" placeholder="Ej. 50" type="number" value={item.price_buy} onChange={handleChange} />
          </Form.Field>
          <Form.Field>
            <label>Total</label>
            <input name="total" placeholder="Total" type="number" value={item.amount * item.price_buy} onChange={handleChange} disabled />
          </Form.Field>
          <Form.Field>
            <label>Descripcion</label>
            <input name="description" placeholder="Descripcion de la compra o venta" value={item.description} onChange={handleChange} />
          </Form.Field>
          <Form.Field>
            <label>Accion</label>
            <Button.Group>
              <Button type="button" positive onClick={(e) => onHandleClick(e, 'in')}>Entrada</Button>
              <Button type="button" onClick={(e) => onHandleClick(e, 'out')}>Salida</Button>
            </Button.Group>
          </Form.Field>
        </Form.Group>
      </Form>
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            Fecha
          </Grid.Column>
          <Grid.Column width={2}>
            Operacion
          </Grid.Column>
          <Grid.Column width={5}>
            Descripcion
          </Grid.Column>
          <Grid.Column width={1}>
            Entrada
          </Grid.Column>
          <Grid.Column width={1}>
            Salida
          </Grid.Column>
          <Grid.Column width={1}>
            Precio
          </Grid.Column>
          <Grid.Column width={1}>
            Cantidad
          </Grid.Column>
          <Grid.Column width={1}>
            Precio
          </Grid.Column>
          <Grid.Column width={1}>
            Total
          </Grid.Column>
        </Grid.Row>
        {
          stockLogs.map((log) => (
            <Grid.Row>
              <Grid.Column width={3}>
                {moment(log.created).format('L LT')}
              </Grid.Column>
              <Grid.Column width={2}>
                {getOperation(operation_types, log.operation_type)}
              </Grid.Column>
              <Grid.Column width={5}>
                {log.description}
              </Grid.Column>
              {getStockOperation(operation_types, log)}
              <Grid.Column color="blue" width={1}>
                {log.amount}
              </Grid.Column>
              <Grid.Column color="blue" width={1}>
                {Math.round(log.price_sale * 100) / 100}
              </Grid.Column>
              <Grid.Column color="blue" width={1}>
                {log.total}
              </Grid.Column>
            </Grid.Row>
          ))
        }
      </Grid>
      <div>
        <Header as="h2" textAlign="center">
          <Header.Content><Button onClick={handleLoadMore}>Cargar mas</Button></Header.Content>
        </Header>
      </div>
    </>
  );
};

ProductStockUpdate.propTypes = {
  current: PropTypes.any.isRequired,
};

export { ProductStockUpdate };
