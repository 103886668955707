import React, { useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';

import actions from 'store/actions/globals/v1';
import api from 'generics/api/globals/v1';
import { form } from 'generics/functions/globals';
import { toast } from 'generics/services/globals/v4';

import { IButton, UImage, ContentTitle } from 'components';
import { AREA_UPDATE_TITLE } from '../areas.constants';

const HomeAreaUpdate = ({ area }) => {
  const dispatch = useDispatch();
  const [item, set_item] = useReducer(form.reducer, {
    _id: area._id,
    name: area.name,
    image_id: area.image_id,
    description: area.description,
  });

  const handle_submit = (e) => {
    e.preventDefault();
    api.areas.update(item)
    .then(({ data }) => {
      dispatch(actions.apps.set_area(data));
      toast.success('se actualizo correctamente');
    })
    .catch(toast.api_danger);
  };

  const handle_change = (event) => {
    set_item({
      name: event.target.name,
      value: event.target.value,
    });
  };

  const handle_image = useCallback((data) => {
    set_item(data);
  }, []);

  return (
    <>
      <ContentTitle config={AREA_UPDATE_TITLE} />
      <Form onSubmit={handle_submit} inverted>
        <Form.Field>
          <label>Nombre</label>
          <input name="name" placeholder="Nombre" value={item.name} onChange={handle_change} />
        </Form.Field>
        <Form.Field>
          <label>Descripción</label>
          <textarea name="description" placeholder="Descripción" value={item.description} onChange={handle_change} />
        </Form.Field>
        <UImage image={item.image_id} height="150px" quality={1080} on_callback={handle_image} />

        <IButton label="Actualizar area" icon="checkmark" submit />
      </Form>
    </>
  );
};

HomeAreaUpdate.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { HomeAreaUpdate };
