import { useState } from 'react';

const useFormReducer = (data) => {
  if (!data) {
    throw new Error('data is indefined');
  }

  const [item, set_item] = useState(data);

  const on_string_change = (event) => {
    set_item({ ...item, [event.target.name]: event.target.value });
  };

  const on_number_change = (event) => {
    set_item({ ...item, [event.target.name]: parseInt(event.target.value, 10) });
  };

  const on_image_change = (event) => {
    set_item({ ...item, ...event });
  };

  const on_uimage_change = (event) => {
    set_item({ ...item, ...event });
  };

  const on_images_change = (event) => {
    set_item({ ...item, image_ids: event });
  };

  const on_checkbox_change = (event, element) => {
    event.stopPropagation();
    set_item({ ...item, [element.name]: element.checked });
  };

  return {
    item,
    set_item,
    on_string_change,
    on_number_change,
    on_image_change,
    on_uimage_change,
    on_images_change,
    on_checkbox_change,
  };
};

export { useFormReducer };
