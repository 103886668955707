export const AREA_UPDATE_TITLE = {
  context: 'administrador',
  title: 'editar area',
  description: 'generator',
};

export const AREA_DETAIL_TITLE = {
  context: 'administrador',
  title: 'area actual',
  description: 'generator',
};

export const LIST_AREAS_TITLE = {
  context: 'administrador',
  title: 'lista de la areas',
  description: 'cada area es un entorno de trabajo se puede agrupar',
};

export const AREAS_DETAIL_TITLE = {
  context: 'administrador',
  title: 'Detalle de la area',
  description: 'cada area es un entorno de trabajo se puede agrupar',
};
