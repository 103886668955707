import React from 'react';
import PropTypes from 'prop-types';

import './CheckoutInvoiceCart.scss';

const CheckoutInvoiceCart = ({ sale }) => {
  const fill_products = (products) => {
    const MIN = 6;
    const items = [...products];

    if (items.length <= MIN) {
      const count = MIN - items.length;
      for (let i = 0; i < count; i += 1) {
        items.push(null);
      }
      return items;
    }

    return items;
  };

  const render_item = (item, index) => (
    <div key={index} className="cart-summary">
      <div className="amount">
        {item.amount}
      </div>
      <div className="description">
        {item.name}
      </div>
      <div className="price">
        <div style={{ textAlign: 'right' }}>{`${Number(item.price).toFixed(2)}`}</div>
      </div>
      <div className="total">
        <div style={{ textAlign: 'right' }}>{`${Number(item.amount * item.price).toFixed(2)}`}</div>
      </div>
    </div>
  );

  const render_empty = (item, index) => (
    <div key={index} className="cart-summary">
      <div className="amount" />
      <div className="description" />
      <div className="price" />
      <div className="total" />
    </div>
  );

  return (
    <div className="checkout-letter-cart">
      <div className="cart-header">
        <div className="h-cart-summary cart-header">
          <div className="h-amount">
            CANTIDAD
          </div>
          <div className="h-description">
            CONCEPTO
          </div>
          <div className="h-price">
            PRECIO
          </div>
          <div className="h-total">
            TOTAL
          </div>
        </div>
        {
          fill_products(sale.products)
          .map((item, index) => (
            item ? render_item(item, index) : render_empty(item, index)
          ))
        }
      </div>
      <div style={{ textAlign: 'right' }}>
        <div style={{ border: '1px solid #000', padding: '2px 5px', fontWeight: 'bold' }}>{`Total: Bs. ${Number(sale.payment.total).toFixed(2)}`}</div>
      </div>
    </div>
  );
};

CheckoutInvoiceCart.propTypes = {
  sale: PropTypes.any.isRequired,
};

export { CheckoutInvoiceCart };
