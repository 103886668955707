import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { files } from 'generics/services/globals';
import { BASE_PATH } from 'generics/constants';
import default_image from '../../../assets/images/pexels-photo-1714208.jpeg';

import './UImage.scss';

const UImage = ({ image, width, height, on_callback }) => {
  const [item, setItem] = useState({
    image: default_image,
  });
  const style = {
    width,
    height,
  };

  const on_image_select = (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];

      files.upload_images(img, (chuck) => {
        /* eslint-disable no-console */
        console.log('uploading..', Math.round((100 * chuck.loaded) / chuck.total));
      })
      .then(({ data }) => {
        /* eslint-disable no-console */
        console.log(data);

        on_callback({ image_id: data._id });
      })
      .catch((err) => {
        /* eslint-disable no-console */
        console.log(err);
      });
    }
  };

  useEffect(() => {
    setItem({
      image: image ? `${BASE_PATH.FILES}/v1/assets/images/width/1080/${image}` : default_image,
    });
  }, [image]);

  return (
    <div className="h-image-compoment">
      <label>Seleccione una image</label>
      <div className="image" style={style}>
        {
          item && <img alt="" src={item.image} />
        }
      </div>
      <input type="file" name="avatar" onChange={on_image_select} />
    </div>
  );
};

UImage.propTypes = {
  image: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  on_callback: PropTypes.func.isRequired,
};

UImage.defaultProps = {
  image: null,
  width: 'auto',
  height: 'auto',
};

export { UImage };
