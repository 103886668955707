import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CardList } from 'components/lists/v1';

import service from 'generics/api/sales/v2';
import { toast } from 'generics/services/globals/v3';
import { StoreProductToolbar } from './StoreProductToolbar';

const StoreProductList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { area } = useSelector((state) => state.apps);
  const products = useSelector((state) => state.products);
  const store_products = useSelector((state) => state.store_products);
  const { search, store } = useSelector((state) => state.app);
  const [items, set_items] = useState([]);

  const convert = (item) => {
    const product = item.product || {};

    return {
      image_id: product.image_id,
      name: product.name || 'Not found',
      description: product.description || 'Not found',
    };
  };

  const on_select = useCallback((item) => {
    history.push(`/sales/store-products/${item.store_product._id}`);
  }, [history]);

  useEffect(() => {
    set_items([...store_products]);
  }, [products, store_products, search]);

  useEffect(() => {
    if (!store) return;

    service.store_products.find({ area_id: area._id, store_id: store._id })
    .then(({ data }) => {
      set_items(data.map((item, index) => ({ ...item, _id: index })));
    })
    .catch(toast.api_danger);
  }, [dispatch, store, area]);

  return (
    <>
      {area && <StoreProductToolbar area={area} />}
      <CardList items={items} convert={convert} on_select={on_select} />
    </>
  );
};

export { StoreProductList };
