import React from 'react';
import { useSelector } from 'react-redux';

import { HeaderLogin } from './session/HeaderLogin';
import { HeaderLogout } from './session/HeaderLogout';
import { HSessionContainer } from './container/HToolbarContainer';

const Header = () => {
  const { account } = useSelector((state) => state.auth);

  return (
    <HSessionContainer>
      {account ? <HeaderLogin /> : <HeaderLogout />}
    </HSessionContainer>
  );
};

export { Header };
