import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import './Order.scss';

const IconOrder = ({ icon, onSelect }) => {

  const handleItemSelect = (e) => {
    e.stopPropagation();
    onSelect();
  };

  return (
    <div className="cart-order" onClick={(e) => handleItemSelect(e)}>
      <Icon name={icon} />
    </div>
  )
};

IconOrder.propTypes = {
  icon: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
};

export { IconOrder };
