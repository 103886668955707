import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import image from '../../../../assets/images/pexels-oleg-magni-1005638.jpg';
import { HToolbarButton } from '../../../container/HToolbarContainer';

import './Chip.scss';

const Chip = ({ item, current, onHandleClick }) => {
  const onClick = (e) => {
    e.stopPropagation();
    onHandleClick(item);
  };

  return (
    <HToolbarButton>
      <div className={classnames('chip', { 'on-select': current && current._id === item._id })} onClick={onClick}>
        <div>
          <img src={item.image || image} alt={item.name} />
        </div>
        <div>
          <h4>{item.name || item.email}</h4>
        </div>
      </div>
    </HToolbarButton>
  )
};

Chip.propTypes = {
  item: PropTypes.object.isRequired,
  current: PropTypes.object.isRequired,
  onHandleClick: PropTypes.func.isRequired,
};

export { Chip };
