import React from 'react';

import { VContainer } from '../../components';

const Explore = () => {
  return (
    // #254f6d
    <VContainer>
      Explorar
    </VContainer>
  );
};

export { Explore };
