import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { get_by_id } from 'generics/api/sales/v0/store.service';
import image from '../../../../assets/images/pexels-oleg-magni-1005638.jpg';

const ApeKioskCardListItem = ({ current, item, onSelect, convert }) => {
  const [data] = useState(convert(item));
  const [store, setStore] = useState(null);

  const handleSelect = (e) => {
    e.stopPropagation();
    onSelect(item);
  };

  useEffect(() => {
    get_by_id({ _id: item.store_id })
    .then(({ data }) => {
      setStore(data);
    })
    .catch(({ response }) => {
      console.log('err :>> ', response.data);
    });
  }, [item]);

  return (
    <div className={classnames('list-item', { 'list-item-select': current && current._id === item._id})} onClick={handleSelect}>
      <div className="image"><img src={data.image || image} alt={data.title} /></div>
      <div className="title">{data.title}</div>
      <div className="subtitle">{store && store.name}</div>
    </div>
  );
};

ApeKioskCardListItem.propTypes = {
  current: PropTypes.object,
  item: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  convert: PropTypes.func.isRequired,
};

export { ApeKioskCardListItem };
