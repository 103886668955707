import React from 'react';
import PropTypes from 'prop-types';

import { Chip } from './Chip';

import './Chip.scss';

const Chips = ({ items, current, onHandleClick }) => (
  <div className="category-chips">
    <Chip
      key={1000000}
      item={{
        name: 'Todos',
        _id: null,
      }}
      current={current}
      onHandleClick={onHandleClick}
    />
    {
      items.map((item) => (
        <Chip key={item._id} item={item} current={current} onHandleClick={onHandleClick} />
      ))
    }
  </div>
);

Chips.defaultProps = {
  current: null,
};

Chips.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  current: PropTypes.any,
  onHandleClick: PropTypes.func.isRequired,
};

export { Chips };
