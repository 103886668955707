import axios from 'axios';

import auth from '../auth.service';

const get_session = () => (axios.create(auth.header()));

const get_by_id = (PATH) => (
  (_id) => {
    if (!_id) {
      throw new Error('_id is undefined');
    }

    return get_session().get(`${PATH}/${_id}`);
  }
);

const find = (PATH) => (
  (params) => {
    if (!params) {
      throw new Error('params is undefined');
    }

    return get_session().get(PATH, { params });
  }
);

const find_by = (PATH) => (
  (pagination, params) => {
    if (!pagination) {
      throw new Error('pagination is undefined');
    }
    if (!params) {
      throw new Error('params is undefined');
    }

    return get_session().get(`${PATH}/page/${pagination.page}/limit/${pagination.limit}`, { params });
  }
);

const create = (PATH) => (
  (params) => {
    if (!params) {
      throw new Error('params is undefined');
    }

    return get_session().post(PATH, params);
  }
);

const update = (PATH) => (
  (_id, params) => {
    if (!_id) {
      throw new Error('_id is undefined');
    }
    if (!params) {
      throw new Error('params is undefined');
    }

    return get_session().put(`${PATH}/${_id}`, params);
  }
);

const remove = (PATH) => (
  (_id) => {
    if (!_id) {
      throw new Error('_id is undefined');
    }

    return get_session().delete(`${PATH}/${_id}`);
  }
);

export default { get_session, get_by_id, find, find_by, create, update, remove };
