import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ACTIONS } from '../../utils/pay.constants';
import { SalesButton } from '../SalesButton';

import './CheckoutPayActions.scss';

const CheckoutPayActions = ({ on_customer, on_payment, payment, set_payment }) => {
  const [action, setAction] = useState(ACTIONS.QUANTITY);
  let timeout = null;
  let text = null;

  const pessKeyAction = (value) => {
    if (text == null && value === '.') {
      return;
    }
    text = text ? text + String(value) : String(value);
    // eslint-disable-next-line
    clearTimeout(timeout);
    // eslint-disable-next-line
    timeout = setTimeout(() => {
      const amount = parseFloat(text);
      text = null;
      set_payment({
        total: payment.total,
        cash: amount,
        refund: amount - payment.total,
      });
    }, 600);
  };

  const amountAction = (value) => {
    const amount = payment.cash + value;
    set_payment({
      total: payment.total,
      cash: amount,
      refund: amount - payment.total,
    });
  };

  const handle_action = (key_action) => {
    switch (key_action.key) {
    case ACTIONS.CUSTOMER.key:
      on_customer();
      break;
    case ACTIONS.PAYMENT.key:
      on_payment();
      break;
    case ACTIONS.ONE.key:
      pessKeyAction(1);
      break;
    case ACTIONS.TWO.key:
      pessKeyAction(2);
      break;
    case ACTIONS.THREE.key:
      pessKeyAction(3);
      break;
    case ACTIONS.FOUR.key:
      pessKeyAction(4);
      break;
    case ACTIONS.FIVE.key:
      pessKeyAction(5);
      break;
    case ACTIONS.SIX.key:
      pessKeyAction(6);
      break;
    case ACTIONS.SEVEN.key:
      pessKeyAction(7);
      break;
    case ACTIONS.EIGHT.key:
      pessKeyAction(8);
      break;
    case ACTIONS.NINE.key:
      pessKeyAction(9);
      break;
    case ACTIONS.ZERO.key:
      pessKeyAction(0);
      break;
    case ACTIONS.QUANTITY.key:
      setAction(ACTIONS.QUANTITY);
      break;
    case ACTIONS.DISCOUNT.key:
      // eslint-disable-next-line
      console.log('unimplemented');
      break;
    case ACTIONS.PRICE.key:
      setAction(ACTIONS.PRICE);
      break;
    case ACTIONS.CANCEL.key:
      break;
    case ACTIONS.DOT.key:
      pessKeyAction('.');
      break;
    case ACTIONS.PLUSLESS.key:
      // eslint-disable-next-line
      console.log('unimplemented');
      break;
    case ACTIONS.CANCEL_PAYMENT.key:
      // eslint-disable-next-line
      console.log('unimplemented');
      break;
    case ACTIONS.TEN.key:
      amountAction(10);
      break;
    case ACTIONS.TWENTY.key:
      amountAction(20);
      break;
    case ACTIONS.FIFTY.key:
      amountAction(50);
      break;
    default:
      // eslint-disable-next-line
      console.log(`case ${key_action} unimplemented`);
      break;
    }
  };

  return (
    <div className="controls-actions">
      <div className="pay-actions">
        <div className="action-customer">
          <SalesButton item={ACTIONS.CUSTOMER} onClick={handle_action} />
        </div>
        <div className="action-payment">
          <SalesButton item={ACTIONS.PAYMENT} onClick={handle_action} />
        </div>
      </div>
      <div className="numbers-actions-vertical">
        <div className="actions">
          <div className="numbers-actions-horizontal">
            <div className="actions">
              <SalesButton item={ACTIONS.SEVEN} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.EIGHT} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.NINE} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.FIFTY} current={action} onClick={handle_action} />
            </div>
          </div>
        </div>
        <div className="actions">
          <div className="numbers-actions-horizontal">
            <div className="actions">
              <SalesButton item={ACTIONS.FOUR} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.FIVE} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.SIX} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.TWENTY} current={action} onClick={handle_action} />
            </div>
          </div>
        </div>
        <div className="actions">
          <div className="numbers-actions-horizontal">
            <div className="actions">
              <SalesButton item={ACTIONS.ONE} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.TWO} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.THREE} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.TEN} current={action} onClick={handle_action} />
            </div>
          </div>
        </div>
        <div className="actions">
          <div className="numbers-actions-horizontal">
            <div className="actions">
              <SalesButton item={ACTIONS.CANCEL_PAYMENT} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.ZERO} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.DOT} onClick={handle_action} />
            </div>
            <div className="actions">
              <SalesButton item={ACTIONS.CANCEL} onClick={handle_action} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CheckoutPayActions.propTypes = {
  on_customer: PropTypes.func.isRequired,
  on_payment: PropTypes.func.isRequired,
  payment: PropTypes.any.isRequired,
  set_payment: PropTypes.func.isRequired,
};

export { CheckoutPayActions };
