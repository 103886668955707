import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Search } from 'semantic-ui-react';

import api from 'generics/api/globals/v1';
import { toast } from 'generics/services/globals/v4';
import { FormLabels } from 'components/forms/v1';
import { Administrators } from './Administrators';

const SearchAdministrators = ({ item, on_update }) => {
  const [value, set_value] = useState('');
  const [accounts, set_accounts] = useState([...item.accounts]);
  const [results, set_results] = useState([]);

  const on_remove = useCallback((account) => {
    const items = accounts.filter((data) => data._id !== account._id);
    set_accounts(items);
    on_update(items);
  }, [accounts, on_update]);

  const on_search_select = (selected) => {
    const is_select = accounts.find((data) => data._id === selected._id);
    if (is_select) return;

    const data = {
      _id: selected._id,
      profiles: ['administrator'],
    };

    const items = [data, ...accounts];
    set_accounts(items);
    on_update(items);
  };

  const result_renderer = ({ _id, email }) => (<h5 key={_id}>{email}</h5>);

  const on_search_change = useCallback((e, account) => {
    e.preventDefault();
    set_value(account.value);
    api.accounts.find_user({ email: account.value })
    .then(({ data }) => {
      set_results(data);
    })
    .catch(toast.api_danger);
  }, []);

  useEffect(() => {
    set_accounts(item.accounts);
  }, [item]);

  return (
    <>
      <FormLabels.Field>
        <Search
          resultRenderer={result_renderer}
          onResultSelect={(e, data) => {
            on_search_select(data.result);
            set_value('');
          }}
          onSearchChange={on_search_change}
          results={results}
          value={value}
        />
      </FormLabels.Field>
      <Administrators items={accounts} on_remove={on_remove} />
    </>
  );
};
SearchAdministrators.propTypes = {
  item: PropTypes.any.isRequired,
  on_update: PropTypes.func.isRequired,
};

export { SearchAdministrators };
