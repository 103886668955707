import React from 'react';
import PropTypes from 'prop-types';

import { ListItem } from './ListItem';

import './List.scss';

const List = ({ current, items, onSelect }) => {
  const render_item = (item, index) => (
    <ListItem key={index} current={current} item={item} onSelect={onSelect} />
  );

  return (
    <div className="checkout-list">
      {
        items.map((item, index) => render_item(item, index))
      }
    </div>
  );
};

List.propTypes = {
  current: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSelect: PropTypes.func.isRequired,
};

List.defaultProps = {
  current: null,
};

export { List };
