import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { GENERIC, BACKGROUND } from 'generics/constants';
import { Screen } from 'components/screens/v2';
import { HContent, VContainer, VContent, CContainer, Button } from 'components';
import { Header } from '../header/Header';

import './Home.scss';

const Home = () => {
  const history = useHistory();
  const { account } = useSelector((state) => state.auth);

  const handle_select = () => {
    history.push(GENERIC.APP_BASE_URL);
  };

  return (
    <Screen background={BACKGROUND}>
      <HContent>
        <VContainer>
          <Header />
          <VContent className="app-bg">
            <CContainer>
              <div className="home-title">
                <h1>{GENERIC.APP_FULL_NAME}</h1>
                <p>
                  Bienvenido a UnBoliviano
                </p>
                <p>
                  la aplicación que ayuda que la gestion
                </p>
                <p>
                  de las ventas
                </p>
                <p>
                  sea mas sencilla
                </p>
                {
                  account && (
                    <div>
                      <CContainer>
                        <Button label={GENERIC.APP_FULL_NAME} icon="checkmark" onClick={handle_select} />
                      </CContainer>
                    </div>
                  )
                }
              </div>
            </CContainer>
          </VContent>
        </VContainer>
      </HContent>
    </Screen>
  );
};

export { Home };
