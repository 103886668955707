class StorageService {
  static validate_key(key) {
    if (!key) {
      throw new Error('key is undefined');
    }
  }

  static save(key, value, state) {
    this.validate_key(key);
    if (!value) {
      throw new Error('value is undefined');
    }
    if (state) {
      localStorage.setItem(key, value);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  static load(key, state) {
    this.validate_key(key);
    if (state) {
      return localStorage.getItem(key);
    }
    return JSON.parse(localStorage.getItem(key));
  }

  static remove(key) {
    this.validate_key(key);
    localStorage.removeItem(key);
  }
}

export { StorageService };
