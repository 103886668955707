import axios from 'axios';

import { BASE_PATH } from 'generics/constants';
import { AuthService } from './auth.service';

// supplier
const PATH = `${BASE_PATH.ACCOUNTS}/v1/accounts`;

const find_user = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).get(`${PATH}/find`, { params });
};

const get_by_id = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).get(`${PATH}/${params._id}`);
};

const remove = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).delete(`${PATH}/${params._id}`);
};

export { find_user, get_by_id, remove };
