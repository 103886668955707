import { GENERIC } from 'generics/constants';

export const ROUTE = {
  LIST: `${GENERIC.APP_BASE_URL}/sales-points`,
  NEW: 'sales-points/new',
};

const CONTEXT = 'Ventas';
const TITLE = 'puntos de venta';
export const ITEM_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'puntos de venta de los vendedores',
};
export const ITEMS_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'puntos de venta de los vendedores',
};
