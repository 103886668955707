import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import './ToolbarList.scss';

const ToolbarList = ({ icon, label, links, select }) => {
  const [selected, setSelected] = useState(select);

  const history = useHistory();
  const onHandleRoute = (item) => {
    setSelected(item);
    history.push(item.uri);
  };

  const render_link = (link, index) => (
    <div
      key={index}
      className={selected && link.uri === selected.uri ? 'toolbar-list-item active' : 'toolbar-list-item'}
      onClick={() => onHandleRoute(link)}
    >
      {
        icon && <div className="item-icon"><List.Icon name={link.icon} size="large" /></div>
      }
      {
        label && <div className="item-label">{link.label}</div>
      }
    </div>
  );

  return (
    <div className="toolbar-list">
      {links.map(render_link)}
    </div>
  );
};

ToolbarList.propTypes = {
  icon: PropTypes.bool,
  label: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  select: PropTypes.shape({
    uri: PropTypes.string,
  }),
};

ToolbarList.defaultProps = {
  icon: true,
  label: true,
  select: null,
};

export { ToolbarList };
