import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'generics/services/globals/v4';
import service from 'generics/api/sales/v2';
import actions from 'store/actions/sales';
import { generic } from 'generics/functions/globals';
import { QUERY } from 'generics/constants/service.constants';
import { generator } from 'store/actions';
import * as storeActions from '../../../store/actions/store';
import { HToolbarContainer, HToolbarButton, HToolbarTitle, HToolbarFlex } from '../../container/HToolbarContainer';

const StoreProductToolbar = ({ area }) => {
  const dispatch = useDispatch();
  const [stores, set_stores] = useState([]);
  // const stores = useSelector((state) => state.stores);
  const { store } = useSelector((state) => state.app);

  const on_selection = (e, el) => {
    const data = stores.find((item) => item._id === el.value);
    dispatch(actions.app.set_stock_store(data));
  };

  useEffect(() => {
    dispatch(generator(storeActions, QUERY, { area_id: area._id }));
  }, [dispatch, area]);

  useEffect(() => {
    if (!stores.length) return;

    const data = stores[0];
    dispatch(actions.app.set_stock_store(data));
  }, [dispatch, stores]);

  useEffect(() => {
    service.stores.find({ area_id: area._id })
    .then(({ data }) => {
      set_stores(data);
    })
    .catch(toast.api_danger);
  }, [dispatch, area]);

  return (
    <HToolbarContainer>
      <HToolbarTitle>
        Almacen
      </HToolbarTitle>
      <HToolbarFlex />
      <HToolbarButton>
        {/* <Search onSearchChange={(e, el) => on_selection(e, el, 'search')} /> */}
      </HToolbarButton>
      <HToolbarButton>
        <Select
          placeholder="Seleccione un almacen"
          value={store && store._id}
          onChange={on_selection}
          options={generic.get_options(stores, 'name')}
        />
      </HToolbarButton>
    </HToolbarContainer>
  );
};

StoreProductToolbar.propTypes = {
  area: PropTypes.any.isRequired,
};

export { StoreProductToolbar };
