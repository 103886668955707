import React from 'react';
import PropTypes from 'prop-types';

import './HToolbarContainer.scss';

const HSessionContainer = ({ children }) => (
  <div className="h-session-container">
    {children}
  </div>
);

HSessionContainer.propTypes = {
  children: PropTypes.node,
};

HSessionContainer.defaultProps = {
  children: null,
};

const HToolbarContainer = ({ children }) => (
  <div className="h-toolbar-container">
    {children}
  </div>
);

HToolbarContainer.propTypes = {
  children: PropTypes.node,
};

HToolbarContainer.defaultProps = {
  children: null,
};

const HToolbarTitle = ({ children }) => (
  <div className="h-toolbar-title">
    {children}
  </div>
);

HToolbarTitle.propTypes = {
  children: PropTypes.node,
};

HToolbarTitle.defaultProps = {
  children: null,
};

const HToolbarCover = ({ children }) => (
  <div className="h-toolbar-cover">
    {children}
  </div>
);

HToolbarCover.propTypes = {
  children: PropTypes.node,
};

HToolbarCover.defaultProps = {
  children: null,
};

const HToolbarFlex = ({ children }) => (
  <div className="h-toolbar-flex">
    {children}
  </div>
);

HToolbarFlex.propTypes = {
  children: PropTypes.node,
};

HToolbarFlex.defaultProps = {
  children: null,
};

const HToolbarButton = ({ children }) => (
  <div className="h-toolbar-button">
    {children}
  </div>
);

HToolbarButton.propTypes = {
  children: PropTypes.node,
};

HToolbarButton.defaultProps = {
  children: null,
};

export {
  HSessionContainer,
  HToolbarContainer,
  HToolbarButton,
  HToolbarCover,
  HToolbarFlex,
  HToolbarTitle,
};
