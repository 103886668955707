import { types } from 'store/actions/kiosk/store_products.actions';

const initial_state = {
  store_products: [],
};

const reducer = (state = initial_state, { type, data }) => {
  switch (type) {
  case types.KIOSK_STORE_PRODUCT_SET: {
    return { ...state, store_products: [...data] };
  }
  case types.KIOSK_STORE_PRODUCT_SET_ONE: {
    return { ...state, store_products: [data, ...state.store_products] };
  }
  case types.KIOSK_STORE_PRODUCT_UPDATE: {
    const store_products = state.store_products.map(
      (item) => (item._id === data._id ? { ...data } : item),
    );
    return { ...state, store_products };
  }
  case types.KIOSK_STORE_PRODUCT_REMOVE: {
    const store_products = state.store_products.filter((item) => item._id !== data._id);
    return { ...state, store_products };
  }
  default:
    return state;
  }
};

export default reducer;
