import React, { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Checkbox, Form } from 'semantic-ui-react';

import { GENERIC, BACKGROUND } from 'generics/constants';
import { Screen } from 'components/screens/v2';
import actions from 'store/actions/globals/v1';
import api from 'generics/api/globals/v1';
import { form } from 'generics/functions/globals';
import { toast } from 'generics/services/globals/v4';
import { Header as Title } from 'components/titles/v2';
import { Container } from 'components/grids/v3';

import { HContent, IButton, VContainer, VContent, CContainer } from 'components';
import { Header } from '../header/Header';

import './Register.scss';

const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [item, set_item] = useReducer(form.reducer, {});

  const on_submit = (e) => {
    e.preventDefault();
    if (!item.email) {
      toast.danger('agregar un email correctamente');
      return;
    }
    if (!item.password) {
      toast.danger('agregar un password correctamente');
      return;
    }
    if (!item.eula) {
      toast.danger('los terminos y condicion no estan aceptados');
      return;
    }

    api.accounts.create(item)
    .then(({ data }) => {
      dispatch(actions.accounts.set(data));
      history.push(GENERIC.APP_BASE_URL);
    })
    .catch(toast.api_danger);
  };

  const on_cancel = () => {
    history.push('/');
  };

  const on_change = (event) => {
    set_item({
      name: event.target.name,
      value: event.target.value,
    });
  };

  const on_checkbox_change = (e, el) => {
    e.stopPropagation();
    set_item({
      name: el.name,
      value: el.checked,
    });
  };

  const login_form = () => (
    <Form inverted onSubmit={on_submit}>
      <Form.Field>
        <label>Email</label>
        <input name="email" value={item.email} type="text" placeholder="example@example.com" onChange={on_change} />
      </Form.Field>
      <Form.Field>
        <label>Password</label>
        <input name="password" value={item.password} type="password" placeholder="****" onChange={on_change} />
      </Form.Field>
      <Form.Field>
        <Checkbox
          name="eula"
          checked={item.eula}
          label="Estoy de acuerdo con los terminos y condiciones"
          onChange={on_checkbox_change}
        />
      </Form.Field>

      <IButton label="Cancel" icon="remove" onClick={on_cancel} />
      <IButton label="Crear cuenta" icon="checkmark" submit />
    </Form>
  );

  return (
    <Screen background={BACKGROUND}>
      <HContent>
        <VContainer>
          <Header />
          <VContent>
            <CContainer>
              <Container width="25rem" height="auto">
                <Title title="Cuenta nueva" />
                {login_form()}
              </Container>
            </CContainer>
          </VContent>
        </VContainer>
      </HContent>
    </Screen>
  );
};

export { Register };
