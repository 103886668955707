export const types = {
  STORE_SHOP: 'store-shop',
  ADD_TO_CART: 'add-to-cart',
  REMOVE_TO_CART: 'remove-to-cart',
  SELECT_ORDER: 'select-order',
  ADD_ORDER: 'add-order',
  REMOVE_ORDER: 'remove-order',
  SELECT_CART_ITEM: 'select-cart-item',
  CLEAR_CART_ITEM: 'clear-cart-item',
  PAY_ACTION: 'pay-action',
  CLEAN_CART: 'clean-cart',
  OPEN_CLIENT_DIALOG: 'open-client-dialog',
};

const store_shop = (data) => ({
  type: types.STORE_SHOP,
  data,
});

const add_to_cart = (data) => ({
  type: types.ADD_TO_CART,
  data,
});

const remove_to_cart = (data) => ({
  type: types.REMOVE_TO_CART,
  data,
});

const select_order = (data) => ({
  type: types.SELECT_ORDER,
  data,
});

const add_order = (data) => ({
  type: types.ADD_ORDER,
  data,
});

const remove_order = (data) => ({
  type: types.REMOVE_ORDER,
  data,
});

const select_cart_item = (data) => ({
  type: types.SELECT_CART_ITEM,
  data,
});

const clear_cart_item = (data) => ({
  type: types.CLEAR_CART_ITEM,
  data,
});

const pay_action = (data) => ({
  type: types.PAY_ACTION,
  data,
});

const clean_cart = (data) => ({
  type: types.CLEAN_CART,
  data,
});

const open_client_dialog = (data) => ({
  type: types.OPEN_CLIENT_DIALOG,
  data,
});

export default {
  store_shop,
  add_to_cart,
  remove_to_cart,
  add_order,
  remove_order,
  select_order,
  select_cart_item,
  clear_cart_item,
  pay_action,
  clean_cart,
  open_client_dialog,
};
