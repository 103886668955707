import React, { useCallback, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Flex, Container } from 'components/grids/v3';
import { VNavbar } from 'components/headers/v1';
import { AccountDetail } from './account/AccountDetail';
import { AccountUpdate } from './account/AccountUpdate';

import './Accounts.scss';

const Accounts = () => {
  const match = useRouteMatch();
  const { account } = useSelector((state) => state.auth);
  const [link, set_link] = useState(null);
  const [links] = useState([
    { _id: '1', label: 'Account', icon: 'home', uri: `${match.url}/detail` },
    { _id: '2', label: 'Editar', icon: 'edit', uri: `${match.url}/update` },
  ]);

  const on_select = useCallback((data) => {
    set_link(data);
  }, []);

  return (
    <Flex direction={Flex.V}>
      <Container width="1200px">
        <VNavbar link={link} links={links} on_select={on_select} />
        <Switch>
          <Route exact path={`${match.path}/detail`}>
            <AccountDetail account={account} />
          </Route>
          <Route exact path={`${match.path}/update`}>
            <AccountUpdate account={account} />
          </Route>
        </Switch>
      </Container>
    </Flex>
  );
};

export { Accounts };
