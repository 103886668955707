import React from 'react';
import PropTypes from 'prop-types';

import { ApeKioskCardListItem } from './ApeKioskCardListItem';

import './ApeKioskCardList.scss';

const ApeKioskCardList = ({ current, items, convert, onSelect }) => (
  <div className="ape-kiosk-card-list">
    {
      items.map((item) => (
        <ApeKioskCardListItem
          current={current}
          key={item._id}
          item={item}
          onSelect={onSelect}
          convert={convert}
        />
      ))
    }
  </div>
);

ApeKioskCardList.propTypes = {
  current: PropTypes.any.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  convert: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export { ApeKioskCardList };
