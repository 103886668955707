import React from 'react';
import PropTypes from 'prop-types';

import './CheckoutInvoiceCart.scss';

const CheckoutInvoiceCart = ({ sale }) => {
  const render_item = (item, index) => (
    <div key={index} className="cart-summary">
      <div className="amount">
        {item.amount}
      </div>
      <div className="description">
        {item.name}
      </div>
      <div className="price">
        <div style={{ textAlign: 'right' }}>{`${Number(item.price).toFixed(2)}`}</div>
      </div>
      <div className="total">
        <div style={{ textAlign: 'right' }}>{`${Number(item.amount * item.price).toFixed(2)}`}</div>
      </div>
    </div>
  );

  return (
    <div className="checkout-invoice-cart">
      <div className="cart-header">
        <div className="cart-summary cart-header">
          <div className="amount">
            CANT
          </div>
          <div className="description">
            CONCEPTO
          </div>
          <div className="price">
            PRECIO
          </div>
          <div className="total">
            TOTAL
          </div>
        </div>
        {
          sale.products.map((item, index) => render_item(item, index))
        }
      </div>
      <div style={{ textAlign: 'right' }}>
        <h4 style={{ margin: '0px' }}>{`Total: Bs. ${Number(sale.payment.total).toFixed(2)}`}</h4>
        <div>{`Efectivo: Bs. ${Number(sale.payment.cash).toFixed(2)}`}</div>
        <div>{`Cambio: Bs. ${Number(sale.payment.refund).toFixed(2)}`}</div>
      </div>
    </div>
  );
};

CheckoutInvoiceCart.propTypes = {
  sale: PropTypes.any.isRequired,
};

export { CheckoutInvoiceCart };
