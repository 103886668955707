import React from 'react';
import PropTypes from 'prop-types';

import './Screen.scss';

const Screen = ({ children, background }) => {
  const style = {
    background,
  };

  return (
    <div className="screen" style={style}>
      {children}
    </div>
  );
};

Screen.propTypes = {
  background: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export { Screen };
