import { types } from 'store/actions/sales/store_products.actions';

const items = [];

const reducer = (state = items, { type, data }) => {
  switch (type) {
  case types.STORE_PRODUCTS_QUERY: {
    return [...data];
  }
  case types.STORE_PRODUCTS_CREATE: {
    return [data, ...state];
  }
  case types.STORE_PRODUCTS_INCREASE:
  case types.STORE_PRODUCTS_EDIT: {
    return state.map((item) => (item._id === data._id ? { ...data } : item));
  }
  case types.STORE_PRODUCTS_REMOVE: {
    return state.filter((item) => item._id !== data._id);
  }
  default:
    return state;
  }
};

export default reducer;
