export const types = {
  SET_AREA: 'set-area',
};

const set_area = (data) => ({
  type: types.SET_AREA,
  data,
});

export default { set_area };
