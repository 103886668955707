import React from 'react';
import PropTypes from 'prop-types';

import { Order } from './print/Order';
import { Invoice } from './print/Invoice';
import { Receipt } from './print/Receipt';
import { None } from './print/None';

import './PrintInvoice.scss';

const PrintInvoice = ({ sale }) => {
  const render_invoice = () => {
    switch (sale.payment_type) {
    case 'invoice':
      return <Invoice sale={sale} />;
    case 'receipt':
      return <Receipt sale={sale} />;
    case null:
      return <None sale={sale} />;
    default:
      break;
    }

    return null;
  };

  return (
    <div id="checkout-invoice">
      {render_invoice()}
      {sale.order_type && <Order sale={sale} />}
    </div>
  );
};

PrintInvoice.propTypes = {
  sale: PropTypes.any.isRequired,
};

export { PrintInvoice };
