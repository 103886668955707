import accounts from './accounts.actions';
import apps from './apps.actions';
import areas from './areas.actions';
import auth from './auth.actions';
import groups from './groups.actions';
import loading from './loading.actions';

export default {
  accounts,
  apps,
  areas,
  auth,
  groups,
  loading,
};
