const CASH_TYPES = [{
  key: 'cash',
  value: 'Al Contado',
}, {
  key: 'transfer',
  value: 'Transferencia',
}];

const ORDER_TYPES = [{
  key: 'order',
  value: 'Orden',
}, {
  key: 'delivery',
  value: 'Entrega',
}, {
  key: null,
  value: 'Ninguno',
}];

const PAYMENT_TYPES = [{
  key: 'receipt',
  value: 'Recibo',
}, {
  key: 'invoice',
  value: 'Factura',
}, {
  key: null,
  value: 'Ninguno',
}];

export { CASH_TYPES, ORDER_TYPES, PAYMENT_TYPES };
