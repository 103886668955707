import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import { GENERIC } from 'generics/constants';
import api from 'generics/api/billings/v1';
import { Header, EmptyInfo } from 'components/titles/v2';
import { toast } from 'generics/services/globals/v4';
import { Flex, Container } from 'components/grids/v3';

import { List } from './list/List';
import { generate_file } from '../utils/excel';
import { DateSelector } from '../utils/DateSelector';

const CheckoutHistory = ({ area }) => {
  const history = useHistory();
  const [items, set_items] = useState([]);

  const parse_data = () => (
    items.map((item) => ({
      numeracion: item.index,
      fecha: moment(item.completed).format('MM/DD/YYYY LT'),
      encargado: item.account_name,
      razon_social: item.customer_name,
      descripcion: item.description,
      precio: item.price,
      nombre: item.payment ? 'CERRADO' : 'PAGADO',
    }))
  );

  const on_search = useCallback((range) => {
    api.bookings.report({ area_id: area._id, ...range })
    .then(({ data }) => {
      set_items(data);
    })
    .catch(toast.api_danger);
  }, [area]);

  const on_download = () => {
    generate_file(parse_data(), 'checkouts', 'checkouts');
  };

  const on_select = useCallback((item) => {
    history.push(`${GENERIC.APP_BASE_URL}/main/sales/${item._id}/invoices`);
  }, [history]);

  return (
    <Flex direction={Flex.V}>
      <Container width="1200px">
        <Header title="Lista de registros en el hotel" />
        <DateSelector on_search={on_search} on_download={on_download} />
        <Flex.Item>
          {(items.length === 0) && <EmptyInfo description="No existe checkout registradas este dia" />}
          {(items.length > 0) && <List items={items} on_select={on_select} />}
        </Flex.Item>
      </Container>
    </Flex>
  );
};

CheckoutHistory.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image_id: PropTypes.string,
  }).isRequired,
};

export { CheckoutHistory };
