import { types } from 'store/actions/kiosk/toolbar.actions';

const item = {
  point: null,
};

const reducer = (state = item, { type, data }) => {
  switch (type) {
  case types.TOOLBAR_SET: {
    return {
      ...state.stat,
      point: data,
    };
  }
  case types.TOOLBAR_CLEAR: {
    return {
      ...state.stat,
      point: null,
    };
  }
  default:
    return state;
  }
};

export default reducer;
