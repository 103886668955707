import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './SalesButton.scss';

const SalesButton = ({ current, item, onClick }) => {

  const handleOnSelect = (e) => {
    e.stopPropagation();
    onClick(item);
  };

  return (
    <div className={classnames('summary-btn', { 'summary-btn-select': current && current.key === item.key})} onClick={handleOnSelect}>
      {item.value}
    </div>
  )
};

SalesButton.propTypes = {
  current: PropTypes.object,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

SalesButton.defaultProps = {
  current: null,
  item: {
    key: null,
    value: 'Not found'
  },
  onClick: () => {
    throw new Error('function is unimplemented');
  }
};

export { SalesButton };
