import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import api from 'generics/api/billings/v1';
import { toast } from 'generics/services/globals/v4';

import { InvoicesInvoice } from './invoice/invoices_invoice';
import { LetterInvoice } from './letter/letter_invoice';

import './Invoice.scss';

const Invoice = ({ sale }) => {
  const [invoice, set_invoice] = useState(null);

  useEffect(() => {
    api.invoices.get_by_id(sale._id)
    .then(({ data }) => {
      set_invoice(data);
    })
    .catch(toast.api_danger);
  }, [sale]);

  const render_printer_type = () => {
    const print = {
      invoice: <InvoicesInvoice invoice={invoice} />,
      half_card: <LetterInvoice invoice={invoice} />,
    }[invoice.printer_type || 'invoice'];

    if (!print) {
      throw new Error('undefined print type:', invoice.printer_type);
    }

    return print;
  };

  return invoice && render_printer_type();
};

Invoice.propTypes = {
  sale: PropTypes.any.isRequired,
};

export { Invoice };
