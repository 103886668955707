const ACTIONS = {
  CUSTOMER: { key: 'customer', value: 'Cliente'},
  PAYMENT: { key: 'payment', value: 'Pagar'},
  ONE: { key: 'one', value: '1'},
  TWO: { key: 'two', value: '2'},
  THREE: { key: 'three', value: '3'},
  FOUR: { key: 'four', value: '4'},
  FIVE: { key: 'five', value: '5'},
  SIX: { key: 'six', value: '6'},
  SEVEN: { key: 'seven', value: '7'},
  EIGHT: { key: 'eight', value: '8'},
  NINE: { key: 'nine', value: '9'},
  ZERO: { key: 'zero', value: '0'},
  QUANTITY: { key: 'quantity', value: 'Cant'},
  DISCOUNT: { key: 'discount', value: 'Desc'},
  PRICE: { key: 'price', value: 'Precio'},
  CANCEL: { key: 'cancel', value: 'X'},
  DOT: { key: 'dot', value: '.'},
  PLUSLESS: { key: 'plus-less', value: '+/-'},

  CANCEL_PAYMENT: { key: 'cancel-payment', value: 'C'},
  TEN: { key: 'ten', value: '+10'},
  TWENTY: { key: 'twenty', value: '+20'},
  FIFTY: { key: 'fifty', value: '+50'},
};

export { ACTIONS };
