import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Accounts } from './Accounts';

const AccountRoutes = () => {
  const { path } = useRouteMatch();
  const { area } = useSelector((state) => state.apps);
  const { account } = useSelector((state) => state.auth);

  const render_sales = () => (
    <>
      <Route path={`${path}/accounts`}>
        <Accounts area={area} />
      </Route>
    </>
  );

  return (
    <Switch>
      {(area && account) && render_sales()}
    </Switch>
  );
};

export { AccountRoutes };
