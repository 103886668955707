import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Flex, Container } from '../../grids/v4';

import './Dimmer.scss';

const Dimmer = ({ active, children }) => {
  const [loading, set_loading] = useState('Loading');

  useEffect(() => {
    let count = 0;
    if (!active) {
      return null;
    }

    const timer = setInterval(() => {
      count += 1;
      const message = `Loading${'.'.repeat(count)}`;
      set_loading(message);
      // eslint-disable-next-line
      console.info(message);
      if (count >= 5) {
        count = 0;
      }
    }, 500);

    return () => clearInterval(timer);
  }, [active]);

  return (
    <Container position="relative">
      <Container position="absolute">
        {children}
      </Container>
      <Container position="absolute" class_name={active ? 'element-dimmer-show' : 'element-dimmer-hide'}>
        <Flex direction={Flex.V} class_name="element-dimmer">
          <div>
            {loading}
          </div>
        </Flex>
      </Container>
    </Container>
  );
};

Dimmer.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
};

Dimmer.defaultProps = {
  active: false,
  children: null,
};

export { Dimmer };
