import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Search, Form } from 'semantic-ui-react';

import { BUTTON_LABELS, RemoveConfirmation } from 'components/generics/v1';
import { toast } from 'generics/services/globals/v4';
import { ContentTitle } from 'components/titles/v2';
import { Flex } from 'components/grids/v4';
import { OneSelect } from 'components/forms/v1';
import { useFormReducer } from 'generics/functions/forms/v2';
import { UImage } from 'components/images/v3';
import { IButton } from 'components/buttons/v2';
import accounts_service from 'generics/api/accounts/v2';

import { service } from './services';
import { ITEM_HEADER, ROUTE } from './constants';
import { ShopAccountList } from './SalesPointAccountList';

const GItem = ({ area }) => {
  const { _id } = useParams();
  const history = useHistory();
  const [is_new] = useState(_id === undefined);
  const {
    item,
    set_item,
    on_string_change,
    on_image_change,
  } = useFormReducer({ area_id: area._id, accounts: [] });

  const [value, setValue] = useState('');
  const [results, setResults] = useState([]);
  const { loading } = useSelector((state) => state.loading);

  const create = () => {
    service.create(item)
    .then(() => {
      history.push(ROUTE.LIST);
    })
    .catch(toast.api_danger);
  };

  const update = () => {
    service.update(_id, item)
    .then(() => {
      history.push(ROUTE.LIST);
    })
    .catch(toast.api_danger);
  };

  const on_submit = (e) => {
    e.preventDefault();
    if (is_new) {
      create();
    } else {
      update();
    }
  };

  const on_stock_change = (store_id) => {
    set_item({
      ...item,
      store_id,
    });
  };

  const on_remove_callback = useCallback(() => {
    service.remove(_id)
    .then(() => {
      history.push(ROUTE.LIST);
    })
    .catch(toast.api_danger);
  }, [history, _id]);

  useEffect(() => {
    if (is_new) return;

    service.get_by_id(_id)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [is_new, _id, set_item]);

  // FIXME:
  // eslint-disable-next-line
  const cb = useCallback((account_id) => {
    const accounts = item.accounts.filter((account) => account !== account_id);

    service.update(item._id, {
      accounts,
    })
    .then(({ data }) => {
      set_item({
        ...item,
        accounts: [...data.accounts],
      });
    })
    .catch(toast.api_danger);
  });

  const resultRenderer = ({ e_id, email }) => <h5 key={e_id}>{email}</h5>;

  const handleSearchChange = useCallback((e, e_item) => {
    e.preventDefault();
    setValue(e_item.value);
    accounts_service.accounts.find_user({ email: e_item.value })
    .then(({ data }) => {
      setResults(data);
    })
    .catch(toast.api_danger);
  }, []);

  const saveShopUsers = (selected) => {
    const accounts = item.accounts || [];
    service.update(item._id, {
      accounts: [selected._id, ...accounts],
    })
    .then(({ data }) => {
      set_item({
        ...item,
        accounts: [...data.accounts],
      });
    })
    .catch(toast.api_danger);
  };

  const render_buttons = () => {
    if (is_new) {
      return <IButton label={BUTTON_LABELS.CREATE} icon="checkmark" submit />;
    }

    return (
      <>
        <RemoveConfirmation label={BUTTON_LABELS.REMOVE} on_click={on_remove_callback} />
        <IButton label={BUTTON_LABELS.UPDATE} icon="checkmark" submit />
      </>
    );
  };

  return (
    <>
      <ContentTitle config={ITEM_HEADER} />
      <Form onSubmit={on_submit} inverted>
        <Form.Field>
          <label>Nombre</label>
          <input name="name" placeholder="Nombre del punto de venta" value={item.name} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Descripción</label>
          <textarea name="description" placeholder="Descripcion" value={item.description} onChange={on_string_change} />
        </Form.Field>
        <OneSelect selector="name" placeholder="Categoria" path="/v1/stores" params={{ area_id: area._id }} value={item.store_id} on_select={on_stock_change}>
          <label>Almacen</label>
        </OneSelect>
        <UImage image={item.image_id} height="150px" on_callback={on_image_change} />

        {
          !is_new && (
            <>
              <ShopAccountList accounts={item.accounts} cb={cb} />
              <Search
                loading={loading}
                resultRenderer={resultRenderer}
                onResultSelect={(e, data) => {
                  saveShopUsers(data.result);
                  setValue('');
                }}
                onSearchChange={handleSearchChange}
                results={results}
                value={value}
              />
            </>
          )
        }
        <Flex.End>
          {render_buttons()}
        </Flex.End>
      </Form>
    </>
  );
};

GItem.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export { GItem };
