export const AREA_UPDATE_TITLE = {
  context: 'account',
  title: 'actualizar ajustes',
  description: 'generator',
};

export const AREA_DETAIL_TITLE = {
  context: 'account',
  title: 'configuración general',
  description: 'generator',
};
