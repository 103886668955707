import { StorageService } from 'generics/services/globals';

import { types } from 'store/actions/globals/v1/apps.actions';
import { types as auth_types } from 'store/actions/globals/v1/auth.actions';

const initialState = {
  area: null,
};

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
  case types.SET_AREA: {
    StorageService.save('area', data);
    return { ...state, area: data };
  }
  case auth_types.LOGOUT: {
    return { ...state, area: null };
  }
  default:
    return state;
  }
};

export default reducer;
