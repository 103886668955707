import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { BACKGROUND } from 'generics/constants';
import { Shops } from './Shops';
import { Header } from '../../header/Header';
import { Screen, Container } from '../../container';
import { VContainer, VContent, CenterContainer } from '../../components/index';

import './Configuration.scss';

const Configuration = () => {
  const { account } = useSelector((state) => state.auth);
  const { area } = useSelector((state) => state.apps);

  const [currentArea, setCurrentArea] = useState(area);
  const [currentAccount, setCurrentAccount] = useState(account);

  useEffect(() => {
    setCurrentArea(area);
  }, [area]);

  useEffect(() => {
    setCurrentAccount(account);
  }, [account]);

  return (
    <Screen background={BACKGROUND}>
      <Container>
        <Header />
        <VContainer>
          <VContent>
            <CenterContainer>
              <div className="config-container">
                <h2>Selecione un punto de venta:</h2>
                {
                  (currentArea && currentAccount)
                  && <Shops area={currentArea} account={currentAccount} />
                }
              </div>
            </CenterContainer>
          </VContent>
        </VContainer>
      </Container>
    </Screen>
  );
};

export { Configuration };
