import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Flex, FormContainer } from 'components/grids/v2';

import { TerminatesEdit } from './TerminatesEdit';
import { TerminatesHistory } from './TerminatesHistory';

const Terminates = ({ area }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/create`}>
        <Flex.Center>
          <FormContainer>
            <TerminatesEdit area={area} />
          </FormContainer>
        </Flex.Center>
      </Route>
      <Route exact path={`${path}`}>
        <TerminatesHistory area={area} />
      </Route>
    </Switch>
  );
};

Terminates.propTypes = {
  area: PropTypes.any.isRequired,
};

export { Terminates };
