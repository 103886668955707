import { GENERIC } from 'generics/constants';

export const URI = {
  get: '/v1/categories',
  get_by_id: '/v1/categories/{_id}',
  post: '/v1/categories',
  put: '/v1/categories/{_id}',
  remove: '/v1/categories/{_id}',
};
export const ROUTE = {
  list: `${GENERIC.APP_BASE_URL}/categories`,
  new: 'categories/new',
};
export const CONTEXT = 'Categorias';
export const TITLE = 'lista de categorias';
export const ITEM_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'area de creacion y edicion de categorias',
};
export const ITEMS_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'lista de categorias',
};
export const BUTTON_LABELS = {
  NEW: 'Crear categoria',
  REMOVE: 'Eliminar categoria',
  CREATE: 'Crear categoria',
  UPDATE: 'Actualizar categoria',
};
