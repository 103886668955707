import * as service from 'generics/api/sales/v0/store.service';

export const type = {
  STORE_QUERY: 'convertion-query',
  STORE_CREATE: 'convertion-create',
  STORE_EDIT: 'convertion-edit',
  STORE_REMOVE: 'convertion-remove',
  STORE_SUCCESS: 'convertion-success',
  STORE_FAILURE: 'convertion-failure',
};

const query = (data) => ({
  type: type.STORE_QUERY,
  data,
});

const create = (data) => ({
  type: type.STORE_CREATE,
  data,
});

const edit = (data) => ({
  type: type.STORE_EDIT,
  data,
});

const remove = (data) => ({
  type: type.STORE_REMOVE,
  data,
});

export { query, create, edit, remove, service };
