import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Screen } from 'components/screens/v2';

import { BACKGROUND } from 'generics/constants';
import { Header } from '../../header/Header';
import { Container, SummaryContainer } from '../../container';

import { Shops } from '../configuration/Shops';
import { KioskCart } from './cart/KioskCart';
import { KioskProducts } from './KioskProducts';

import { Checkout } from './billings/checkout/Checkout';
import { Invoices } from './billings/invoices/Invoices';

const Kiosk = ({ area }) => {
  const { path } = useRouteMatch();
  const { account } = useSelector((state) => state.auth);
  const { sales_point } = useSelector((state) => state.kiosk);
  const [currentAccount, setCurrentAccount] = useState(account);

  useEffect(() => {
    setCurrentAccount(account);
  }, [account]);

  const render_sales_point = () => (
    <>
      <Container>
        <Header />
        {(area && currentAccount) && <KioskProducts area={area} />}
      </Container>
      <SummaryContainer>
        <KioskCart />
      </SummaryContainer>
    </>
  );
  const render_store_selector = () => (
    <Container>
      {
        (area && currentAccount) && <Shops area={area} account={currentAccount} />
      }
    </Container>
  );

  return (
    <Screen background={BACKGROUND}>
      {sales_point ? render_sales_point() : render_store_selector()}
      <Switch>
        {/* <Route exact path={path}>
          <Rooms area={area} />
        </Route> */}
        <Route exact path={`${path}/booking/:booking_id/checkout`}>
          <Checkout area={area} />
        </Route>
        <Route exact path={`${path}/sales/:sale_id/invoices`}>
          <Invoices />
        </Route>
        {/* <Route exact path={`${path}/:room_id`}>
          <Room area={area} />
        </Route> */}
      </Switch>
    </Screen>
  );
};

Kiosk.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export { Kiosk };
