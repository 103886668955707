import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'semantic-ui-react';
// import image from '../../../images/pexels-oleg-magni-1005638.jpg';

import './CartItem.scss';

const CartItem = ({ current, item, onSelect, onCancel }) => {
  const cart = item.sale;
  const product = item.product;

  const handleSelect = (e) => {
    e.stopPropagation();
    //e.preventDefault();
    onSelect(item);
  };
  
  const handleCancel = (e) => {
    e.stopPropagation();
    //e.preventDefault();
    onCancel(item);
  };

  return (
    <div className={classnames('cart-item', { 'cart-item-select': current && current._id === item._id })} onClick={handleSelect}>
      <div className="cart-item-container">
        <div className="cart-item-name">{product.name}</div>
        <div className="cart-item-detail">
          {cart.amount} U a {cart.price_sale} ({cart.price_original}) Bs.
        </div>
      </div>
      <div className="cart-item-total">
        <div>{cart.amount * cart.price_sale} Bs.</div>
        <small>{cart.amount * cart.price_original} Bs.</small>
      </div>
      <div className="cart-item-icon" onClick={handleCancel}>
        <Icon name='cancel' size='large' />
      </div>
    </div>
  )
};

CartItem.propTypes = {
  current: PropTypes.object,
  item: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

CartItem.defaultProps = {
  current: null,
  item: {
    cart: {
      name: 'generator',
      price: 10,
      quantity: 1,
      unit: 'Kg.',
      total: 10,
    }
  },
  onSelect: () => {
    throw new Error('function is unimplemented');
  },
  onCancel: () => {
    throw new Error('function is unimplemented');
  }
};

export { CartItem };
