import React, { useEffect, useState, useReducer, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Modal, Form, Button, Search, Grid } from 'semantic-ui-react';

import actions from 'store/actions/kiosk';
import { formReducer } from '../../../../utils';
import { find_customer, create_customer } from 'generics/api/sales/v0/users/users.service';

import './ClientSearchModal.scss';

const ClientSearchModal = () => {
  const { client } = useSelector((state) => state.kiosk);
  const [user, setUser] = useState(client.user);
  const [form, setFrom] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createClient, setCreateClient] = useState(false);
  const [results, setResults] = useState([]);
  const [value, setValue] = useState('');
  const [error] = useState(false);
  const [item, setItem] = useReducer(formReducer, { });
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setItem({
      name: event.target.name,
      value: event.target.value,
    });
  };

  const handleSubmit = () => {
    item.apps = [{
      name: 'sales',
      notification: true,
    }];
    create_customer({
      nit: item.nit,
    }, item)
    .then(({ data }) => {
      dispatch(actions.open_client_dialog({ user: data, is_open: false }));
    })
    .catch((err) => {
      console.log('err :>> ', err);
    });
  };

  const onHandleCreate = (e) => {
    e.preventDefault();
    setCreateClient(true);
  };

  const onHandleCancel = (e) => {
    e.stopPropagation();
    setCreateClient(false);
  };

  const resultRenderer = ({ _id, nit, contributor }) => (
    <div key={_id} className="search-result-item">
      <h5>{nit}</h5>
      <h6>{contributor}</h6>
    </div>
  );

  useEffect(() => {
    setOpen(client.is_open);
    setUser(client.user);
  }, [client]);

  const handleSearchChange = useCallback((e, data) => {
    e.preventDefault();
    setValue(data.value);
    find_customer({page: 1, limit: 10 }, { nit: data.value })
    .then(({ data }) => {
      setResults(data);
    })
    .catch((err) => {
      console.log('err :>> ', err);
    });
  }, []);

  useEffect(() => {
    setLoading(false);
    setItem({});
  }, []);

  return (
    <Modal
      basic
      onClose={
        () => {
        setOpen(false)
        dispatch(open_client_dialog({ is_open: false }));
        }
      }
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Content>
        <Grid columns={3}>
          <Grid.Row>
            {
              user && (
                <Container textAlign='center'>
                  <h1>CI/NIT: {user.nit}</h1>
                  <h1>NOMBRE: {user.contributor}</h1>
                </Container>
              )
            }
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {
                !createClient && (
                <Button size="huge" onClick={onHandleCreate}>
                  Crear cliente
                </Button>
                )
              }
              
            </Grid.Column>
            <Grid.Column floated='right'>
              <Search
                size="huge"
                autoFocus
                loading={loading}
                resultRenderer={resultRenderer}
                onResultSelect={(e, data) => {
                  dispatch(open_client_dialog({ user: data.result, is_open: false }));
                }}
                onSearchChange={handleSearchChange}
                results={results}
                value={value}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        
        {
          createClient && (
            <div>
              
              <Form
                inverted
                size="huge"
                loading={loading}
                // eslint-disable-next-line
                error={error ? true : false}
                onSubmit={(e) => handleSubmit(e)}
                ref={(ref) => setFrom(ref)}
              >
                <Form.Group widths='equal'>
                  <Form.Field>
                    <label>NIT o CI</label>
                    <input name="nit" value={item.nit} placeholder="NIT" onChange={handleChange} />
                  </Form.Field>
                  <Form.Field>
                    <label>Nombre</label>
                    <input name="contributor" value={item.contributor} placeholder="Nombre" onChange={handleChange} />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <label>Correo electronico</label>
                    <input name="email" value={item.email} placeholder="Correo electronico" onChange={handleChange} />
                  </Form.Field>
                  <Form.Field>
                    <label>Numero de contacto</label>
                    <input name="cel" value={item.cel} placeholder="Numero de contacto" onChange={handleChange} />
                  </Form.Field>
                </Form.Group>
              </Form>
              <Button size="huge" onClick={onHandleCancel}>
                Cancelar
              </Button>
              <Button
                size="huge"
                onClick={(e) => {
                  e.stopPropagation();
                  form.handleSubmit();
                }}
              >
                Crear cliente
              </Button>
            </div>
          )
        }
        
        
      </Modal.Content>
    </Modal>
  )
};

export { ClientSearchModal };
