import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'components/grids/v4';

import './Header.scss';

const Header = ({ title }) => (
  <Flex.Scrap class_name="content-3-title">
    <h1>{title}</h1>
  </Flex.Scrap>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export { Header };
