import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import actions from 'store/actions/kiosk/';
import { api, toast } from 'generics/services/globals/v3';

import { HToolbarContainer } from '../../container/HToolbarContainer';
import { Chips } from './chips/Chips';
import { ApeCardList } from './list/ApeCardList';

const KioskProducts = ({ area }) => {
  const { sales_point } = useSelector((state) => state.kiosk);
  const { store_products } = useSelector((state) => state.k_store_products);
  const { categories } = useSelector((state) => state.k_categories);

  const [currentStocks, set_current_stocks] = useState(store_products);
  const [currentCategories, set_current_categories] = useState(categories);
  const [category, setCategory] = useState(null);
  const dispatch = useDispatch();

  const onHandleSelectCategory = useCallback((item) => {
    if (item._id) {
      setCategory(item);
    } else {
      setCategory(null);
    }
  }, []);

  useEffect(() => {
    if (category) {
      const items = store_products.filter((item) => (
        _.includes(item.product.categories, category._id)
      ));
      set_current_stocks([...items]);
    } else {
      set_current_stocks([...store_products]);
    }
  }, [category, store_products]);

  useEffect(() => {
    set_current_categories([...categories]);
  }, [categories]);

  useEffect(() => {
    api.get('/v2/store-products', null, { area_id: area._id, store_id: sales_point.store_id })
    .then(({ data }) => {
      dispatch(actions.store_products.set(data));
    })
    .catch(toast.api_danger);
    api.get('/v1/categories', null, { area_id: area._id })
    .then(({ data }) => {
      dispatch(actions.categories.set(data));
    })
    .catch(toast.api_danger);
  }, [dispatch, area, sales_point]);

  return (
    <>
      <HToolbarContainer>
        <Chips
          items={currentCategories}
          current={category}
          onHandleClick={onHandleSelectCategory}
        />
      </HToolbarContainer>
      <ApeCardList items={currentStocks} />
    </>
  );
};

KioskProducts.propTypes = {
  area: PropTypes.any.isRequired,
};

export { KioskProducts };
