import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Buys } from './buys/Buys';
import { CheckoutHistory } from './checkout/CheckoutHistory';
import { InvoicesHistory } from './invoices/InvoicesHistory';
import { SalesHistory } from './sales/SalesHistory';
import { Terminates } from './terminates/Terminates';

const BillingRoutes = () => {
  const { path } = useRouteMatch();
  const { area } = useSelector((state) => state.apps);
  const { account } = useSelector((state) => state.auth);

  const render_sales = () => (
    <>
      <Route path={`${path}/sales`}>
        <SalesHistory area={area} />
      </Route>
      <Route path={`${path}/buys`}>
        <Buys area={area} />
      </Route>
      <Route path={`${path}/checkout`}>
        <CheckoutHistory area={area} />
      </Route>
      <Route path={`${path}/invoices`}>
        <InvoicesHistory area={area} />
      </Route>
      <Route path={`${path}/terminates`}>
        <Terminates area={area} />
      </Route>
    </>
  );

  return (
    <Switch>
      {(area && account) && render_sales()}
    </Switch>
  );
};

export { BillingRoutes };
