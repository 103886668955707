export const types = {
  TOOLBAR_SET: 'toolbar-set',
  TOOLBAR_CLEAR: 'toolbar-clear',
};

const setToolbar = (data) => ({
  type: types.TOOLBAR_SET,
  data,
});

const clearToolbar = (data) => ({
  type: types.TOOLBAR_CLEAR,
  data,
});

export default { setToolbar, clearToolbar };
