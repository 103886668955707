import React, { useState, useReducer, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Icon, Modal, Form, Checkbox } from 'semantic-ui-react';

import actions from 'store/actions/globals/v1';
import api from 'generics/api/globals/v1';
import { form } from 'generics/functions/globals';
import { toast } from 'generics/services/globals/v4';

import { UImage } from 'components';

const GroupCreateModal = ({ open, set_open }) => {
  const { account } = useSelector((state) => state.auth);
  const organizations = useSelector((state) => state.groups);
  const dispatch = useDispatch();
  const [item, setItem] = useReducer(form.reducer, {
    account_id: account._id,
    enabbled: false,
  });
  const [submit_form, setFrom] = useState(null);

  const on_upload_image = useCallback((data) => {
    setItem({
      name: 'image_id',
      value: data.image_id,
    });
  }, []);

  const on_submit = (e) => {
    e.preventDefault();
    api.groups.create(item)
    .then(() => api.areas.find({}))
    .then(({ data }) => {
      dispatch(actions.apps.set_area(data[0]));
      dispatch(actions.areas.set(data));
    })
    .catch(toast.api_danger);
  };

  const on_change = (event) => {
    setItem({
      name: event.target.name,
      value: event.target.value,
    });
  };

  const on_checkbox_change = (e, el) => {
    e.stopPropagation();
    setItem({
      name: el.name,
      value: el.checked,
    });
  };

  useEffect(() => {
    set_open(false);
  }, [set_open, organizations]);

  return (
    <Modal
      onClose={() => set_open(false)}
      onOpen={() => set_open(true)}
      open={open}
      size="small"
    >
      <Modal.Content>
        <Form
          onSubmit={(e) => on_submit(e)}
          ref={(ref) => setFrom(ref)}
        >
          <Form.Field>
            <label>Nombre</label>
            <input name="name" placeholder="Generator inc." onChange={on_change} />
          </Form.Field>
          <Form.Field>
            <label>Descripción</label>
            <textarea name="description" placeholder="Descripción" value={item.description} onChange={on_change} />
          </Form.Field>
          <Form.Field>
            <Checkbox name="enabled" checked={item.enabled} label="Está habilitado" onChange={on_checkbox_change} />
          </Form.Field>
          <UImage image={item.image_id} height="150px" on_callback={on_upload_image} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => set_open(false)}>
          <Icon name="remove" />
          Cancelar
        </Button>
        <Button onClick={() => { submit_form.handleSubmit(new Event('submit')); }}>
          <Icon name="checkmark" />
          Crear organización
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

GroupCreateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  set_open: PropTypes.func.isRequired,
};

export { GroupCreateModal };
