import { StorageService } from 'generics/services/globals';

const save_cart = (item, cart) => {
  StorageService.save(`cart-${item.key}`, cart);
};

const load_cart = (item) => (StorageService.load(`cart-${item.key}`) || []);

const update_cart_item = (item, action) => {
  // eslint-disable-next-line
  console.log(action);
  return item;
};

export { save_cart, load_cart, update_cart_item };
