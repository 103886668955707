import React, { useCallback } from 'react';
import { Button, Form, Input } from 'semantic-ui-react';

import './PrintOptions.scss';

const PrintOptions = () => {
  const onHandle = useCallback((e) => {
    e.stopPropagation();
    window.print();
    return false;
    // https://parzibyte.me/blog/2017/10/17/imprimir-ticket-en-impresora-termica-usando-javascript/
    // const prtContent = document.getElementById('checkout-invoice');
    // const WinPrint = window.open(
    //  '', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
    // );
    // WinPrint.document.write(prtContent.innerHTML);
    // WinPrint.document.close();
    // WinPrint.focus();
    // WinPrint.print();
    // WinPrint.close();
  }, []);

  return (
    <div className="invoice-print-options">
      <h2>¿Deseas inprimir?</h2>
      <Button content="Imprimir" secondary fluid onClick={onHandle} />
      <h2>¿Enviar por correo?</h2>
      <Form>
        <Form.Field>
          <Input
            action={{
              color: 'teal',
              labelPosition: 'left',
              icon: 'mail',
              content: 'Checkout',
            }}
            actionPosition="left"
            placeholder="Email"
            defaultValue="example@example.com"
          />
        </Form.Field>
        <Button type="submit">Enviar</Button>
      </Form>
      <h2>¿Enviar por correo al encargado?</h2>
      <Form>
        <Form.Field>
          <Input
            action={{
              color: 'teal',
              labelPosition: 'left',
              icon: 'mail',
              content: 'Checkout',
            }}
            actionPosition="left"
            placeholder="Email"
            defaultValue="example@example.com"
          />
        </Form.Field>
        <Button type="submit">Enviar</Button>
      </Form>
    </div>
  );
};

export { PrintOptions };
