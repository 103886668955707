import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Configuration } from './Configuration';

const ConfigurationRoutes = () => {
  const { path } = useRouteMatch();
  const { area } = useSelector((state) => state.apps);
  const { account } = useSelector((state) => state.auth);

  const render_sales = () => (
    <>
      <Route path={`${path}/configuration`}>
        <Configuration />
      </Route>
    </>
  );

  return (
    <Switch>
      {(area && account) && render_sales()}
    </Switch>
  );
};

export { ConfigurationRoutes };
