import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Settings } from './Settings';

const SettingRoutes = () => {
  const { path } = useRouteMatch();
  const { area } = useSelector((state) => state.apps);
  const { account } = useSelector((state) => state.auth);

  const render_sales = () => (
    <>
      <Route path={`${path}/settings`}>
        <Settings area={area} />
      </Route>
    </>
  );

  return (
    <Switch>
      {(area && account) && render_sales()}
    </Switch>
  );
};

export { SettingRoutes };
