import React from 'react';
import QRCode from 'react-qr-code';
import moment from 'moment';
import PropTypes from 'prop-types';

import { CheckoutInvoiceCart } from './CheckoutInvoiceCart';
//  Roll Paper 80 x 297 mm

const InvoicesInvoice = ({ invoice }) => {
  const render_headquarter = (headquarter) => {
    if (!headquarter || headquarter === '') {
      return 'CASA MATRIZ';
    }

    return `CASA MATRIZ - ${headquarter}`;
  };

  return (
    <div id="checkout-invoice" className="invoices-invoice" style={{ width: '300px', fontFamily: 'Verdana, Sans-serif' }}>
      <div style={{ background: '#ffffff', overflowY: 'auto', color: '#000000', padding: '10px' }}>
        <div style={{ textAlign: 'center', fontSize: '16px' }}>
          <h3 style={{ margin: '0px' }}>
            {invoice.area_name}
          </h3>
          <div>
            {`De: ${invoice.area_owner}`}
          </div>
          <div>
            {render_headquarter(invoice.area_headquarter)}
          </div>
        </div>
        <div style={{ textAlign: 'center', fontSize: '12px', marginBottom: '10px' }}>
          <div>
            {invoice.area_address}
          </div>
          <div>
            {invoice.area_phone}
          </div>
          <div>
            {invoice.area_city}
          </div>
        </div>
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <h5 style={{ margin: '0px' }}>
            FACTURA ORIGINAL
          </h5>
          <div>
            {`NIT: ${invoice.area_nit}`}
          </div>
          <h5 style={{ margin: '0px' }}>
            {`FACTURA Nro: ${invoice.invoice_counter}`}
          </h5>
          <div>
            {`AUTORIZACIÓN Nro: ${invoice.invoice_authorization_number}`}
          </div>
          <div>
            {`Actividad Económica: ${invoice.area_activity}`}
          </div>
        </div>
        <div>
          <div>
            {`FECHA: ${moment(invoice.created).format('DD/MM/YYYY h:mm A')}`}
          </div>
          <div>
            {`NIT/CI: ${invoice.customer_index}`}
          </div>
          <div>
            {`SEÑOR(ES): ${invoice.customer_name}`}
          </div>
        </div>
        <CheckoutInvoiceCart sale={invoice} />
        <div>
          <div>
            {`SON: ${invoice.total_literal}`}
          </div>
          <div>
            {`CODIGO CONTROL: ${invoice.control_code_text}`}
          </div>
        </div>
        <div>
          <div>
            {`FECHA LIMITE DE EMISION: ${moment(invoice.invoice_emission_deadline).format('DD/MM/YYYY')}`}
          </div>
        </div>
        <div>
          <div style={{ textAlign: 'center' }}>
            <QRCode
              size={120}
              value={invoice.qr_text}
            />
          </div>
        </div>
        <div>
          <div style={{ textAlign: 'center', fontSize: '12px' }}>
            &quot;ESTA FACTURA CONTRIBUYE AL DESARROLLO DEL PAÍS.
            EL USO ILÍCITO DE ÉSTA SERÁ SANCIONADO DE ACUERDO A LEY&quot;
          </div>
          <div style={{ textAlign: 'center', fontSize: '12px' }}>
            {`${invoice.invoice_consumer_right}`}
          </div>
        </div>
        <div>
          <div>
            {`Emitido por: ${invoice.account_name}`}
          </div>
          <div style={{ textAlign: 'center' }}>
            Gracias por su preferencia...
          </div>
          <div style={{ textAlign: 'center', fontSize: '8px' }}>
            visitors.wargos-solutions.com
          </div>
        </div>
      </div>
    </div>
  );
};

InvoicesInvoice.propTypes = {
  invoice: PropTypes.any.isRequired,
};

export { InvoicesInvoice };
