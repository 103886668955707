import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

import { AccountItem } from './AccountItem';

const ShopAccountList = ({ accounts, cb }) => (
  <List divided relaxed>
    {
      accounts.map((account) => (
        <AccountItem key={account} account_id={account} cb={cb} />
      ))
    }
  </List>
);

ShopAccountList.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.any).isRequired,
  cb: PropTypes.func.isRequired,
};

export { ShopAccountList };
