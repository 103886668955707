import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { InvoicesReceipt } from './invoice/invoices_receipt';
import { LetterReceipt } from './letter/letter_receipt';

const Receipt = ({ sale }) => {
  const { setting } = useSelector((state) => state.area);

  const render_printer_type = () => {
    const print = {
      invoice: <InvoicesReceipt sale={sale} />,
      half_card: <LetterReceipt sale={sale} />,
    }[setting.printer_type || 'invoice'];

    if (!print) {
      throw new Error('undefined print type:', setting.printer_type);
    }

    return print;
  };

  return render_printer_type();
};

Receipt.propTypes = {
  sale: PropTypes.any.isRequired,
};

export { Receipt };
