import { GENERIC } from 'generics/constants';

export const URI = {
  get: '/v1/products',
  get_by_id: '/v1/products/{_id}',
  post: '/v1/products',
  put: '/v1/products/{_id}',
  remove: '/v1/products/{_id}',
};
export const ROUTE = {
  list: `${GENERIC.APP_BASE_URL}/products`,
  new: 'products/new',
};
export const CONTEXT = 'Products';
export const TITLE = 'lista de products';
export const ITEM_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'area de creacion y edicion de products',
};
export const ITEMS_HEADER = {
  context: CONTEXT,
  title: TITLE,
  description: 'lista de products',
};
export const BUTTON_LABELS = {
  NEW: 'Crear product',
  REMOVE: 'Eliminar product',
  CREATE: 'Crear product',
  UPDATE: 'Actualizar product',
};
export const DEFAULT_STOCK = {
  price: 0,
  amount: 0,
  min: 10,
  max: 100,
};
