import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import actions from 'store/actions/kiosk';

import { CartItem } from './CartItem';
import { calc_totals } from '../../../utils/payment';

import './CartItem.scss';

const CartList = () => {
  const { cart, cartItem } = useSelector((state) => state.kiosk);
  const [currentCartItem, setCurrentCartItem] = useState(null);
  const [items, setItems] = useState(cart);
  const dispatch = useDispatch();

  const onSelect = useCallback((item) => {
    dispatch(actions.kiosk.select_cart_item(item));
  }, [dispatch]);

  const onCancel = useCallback((item) => {
    dispatch(actions.kiosk.remove_to_cart(item));
  }, [dispatch]);

  useEffect(() => {
    setCurrentCartItem(cartItem);
  }, [cartItem]);

  useEffect(() => {
    setItems([...cart]);
  }, [cart]);

  return (
    <div className="cart-items">
      {
        items.map((item, index) => (
          <CartItem key={index} current={currentCartItem} item={item} onSelect={onSelect} onCancel={onCancel} />
        ))
      }
      <div className="cart-total">
        Total: {calc_totals(items).sale} Bs.
      </div>
      <div className="cart-total-original">
        Total: {calc_totals(items).original} Bs.
      </div>
    </div>
  );
};

export { CartList };
