import React from 'react';
import PropTypes from 'prop-types';

import { CheckoutPayActions } from './pay/CheckoutPayActions';

import './CheckoutSummary.scss';

const CheckoutSummary = ({ payment, set_payment, on_payment, on_customer }) => (
  <div className="checkout-summary">
    <div className="checkout-orientation">
      <div className="checkout-total-style checkout-total">
        <div className="checkout-orientation">
          <div>
            <h5>Total a pagar</h5>
          </div>
        </div>
        <div className="checkout-orientation">
          <div>
            <h1>{`${payment.total} Bs.`}</h1>
          </div>
        </div>
      </div>
      <div className="checkout-total-style checkout-pay">
        <div className="checkout-orientation">
          <div>
            <h5>Total pagado</h5>
          </div>
          <div>
            <h5>Cambio</h5>
          </div>
        </div>
        <div className="checkout-orientation">
          <div>
            <h1>{`${payment.cash} Bs.`}</h1>
          </div>
          <div>
            <h1>{`${payment.refund} Bs.`}</h1>
          </div>
        </div>
      </div>
    </div>
    <CheckoutPayActions
      on_customer={on_customer}
      on_payment={on_payment}
      payment={payment}
      set_payment={set_payment}
    />
  </div>
);

CheckoutSummary.propTypes = {
  payment: PropTypes.any.isRequired,
  set_payment: PropTypes.func.isRequired,
  on_payment: PropTypes.func.isRequired,
  on_customer: PropTypes.func.isRequired,
};

export { CheckoutSummary };
