import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import './CartSummaryTitle.scss';

const CartSummaryTitle = () => {
  const { client } = useSelector((state) => state.kiosk);
  const [user, setUser] = useState(client.user);
  
  useEffect(() => {
    setUser(client.user);
}, [client]);

  return (
    <div className="cart-summary-title">
      <h1>
        { user ? `${user.contributor} (${user.nit})` : 'Sin cliente' }
      </h1>
    </div>
  )
};

export { CartSummaryTitle };
