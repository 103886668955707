import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'components/grids/v3';
import { FormLabels } from 'components/forms/v1';
import { HImage, ContentTitle } from 'components';
import api from 'generics/api/settings';
import { toast } from 'generics/services/globals/v4';
import { AREA_DETAIL_TITLE } from '../setting.constants';

const SettingDetail = ({ area }) => {
  const [item, set_item] = useState({});

  useEffect(() => {
    api.settings.get_by_id(area._id)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [set_item, area]);

  return (
    <>
      <ContentTitle config={AREA_DETAIL_TITLE} />
      <Flex.Scrap>
        <FormLabels>
          <FormLabels.Field>
            <FormLabels.Label value="NIT" />
            <FormLabels.Input value={item.area_nit} />
          </FormLabels.Field>
          <FormLabels.Field>
            <FormLabels.Label value="Telefono" />
            <FormLabels.Input value={item.area_phone} />
          </FormLabels.Field>
          <FormLabels.Field>
            <FormLabels.Label value="Direccion" />
            <FormLabels.Input value={item.area_address} />
          </FormLabels.Field>
          <FormLabels.Field>
            <FormLabels.Label value="Ciudad" />
            <FormLabels.Input value={item.area_city} />
          </FormLabels.Field>
          <HImage image={item.image_id} height="150px" quality={1080} />
        </FormLabels>
      </Flex.Scrap>
    </>
  );
};

SettingDetail.propTypes = {
  area: PropTypes.any.isRequired,
};

export { SettingDetail };
