import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { BUTTON_LABELS } from 'components/generics/v1';
import { toast } from 'generics/services/globals/v4';
import { ContentTitle } from 'components/titles/v2';
import { IButton } from 'components/buttons/v2';
import { Flex } from 'components/grids/v4';
import { CardList } from 'components/lists/v1';

import { service } from './services';
import { ITEMS_HEADER, ROUTE } from './constants';

const GItems = ({ area }) => {
  const history = useHistory();
  const [items, set_items] = useState([]);

  const on_create = useCallback(() => {
    history.push(ROUTE.NEW);
  }, [history]);

  const on_select = useCallback((item) => {
    history.push(`${ROUTE.LIST}/${item._id}`);
  }, [history]);

  const convert = (item) => ({
    image_id: item.image_id || null,
    name: item.name || 'Not found',
    description: item.description || 'Not found',
  });

  useEffect(() => {
    service.find({ area_id: area._id })
    .then(({ data }) => {
      set_items(data);
    })
    .catch(toast.api_danger);
  }, [area]);

  return (
    <Flex direction={Flex.V}>
      <ContentTitle config={ITEMS_HEADER} />
      <Flex.Item>
        <Flex.End>
          <IButton label={BUTTON_LABELS.NEW} icon="plus" on_click={on_create} />
        </Flex.End>
        <CardList items={items} convert={convert} on_select={on_select} />
      </Flex.Item>
    </Flex>
  );
};

GItems.propTypes = {
  area: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export { GItems };
