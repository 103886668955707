export const types = {
  KIOSK_STORE_PRODUCT_SET: 'kiosk-store-product-set',
  KIOSK_STORE_PRODUCT_SET_ONE: 'kiosk-store-product-set-one',
  KIOSK_STORE_PRODUCT_UPDATE: 'kiosk-store-product-update',
  KIOSK_STORE_PRODUCT_REMOVE: 'kiosk-store-product-remove',
};

const set = (data) => ({
  type: types.KIOSK_STORE_PRODUCT_SET,
  data,
});

const set_one = (data) => ({
  type: types.KIOSK_STORE_PRODUCT_SET_ONE,
  data,
});

const update = (data) => ({
  type: types.KIOSK_STORE_PRODUCT_UPDATE,
  data,
});

const remove = (data) => ({
  type: types.KIOSK_STORE_PRODUCT_REMOVE,
  data,
});

export default { set, set_one, update, remove };
