import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import Select from 'react-select';

import { api, toast } from 'generics/services/globals/v3';
import { dropdowm } from 'generics/functions/forms/v1';

const MultiSelect = ({ selector, placeholder, path, params, _ids, on_select, children }) => {
  const [item, set_item] = useState([]);
  const [items, set_items] = useState([]);

  const on_group_change = (data) => {
    const removed = item.filter((o) => !data.includes(o)).map((o) => o._id);
    const created = data.filter((o) => !item.includes(o)).map((o) => o._id);
    on_select(data.map((o) => o._id), created, removed);
    set_item(data);
  };

  useEffect(() => {
    api.get(path, null, params)
    .then(({ data }) => {
      const aux_items = dropdowm.get_options(data, selector);
      set_items(aux_items);
      const value_aux = [];
      _ids.forEach((_id) => {
        value_aux.push(aux_items.find((aux_item) => aux_item.value === _id));
      });
      set_item(value_aux);
    })
    .catch(toast.api_danger);
  }, [path, params, selector, _ids]);

  return (
    <Form.Field>
      {children}
      <Select
        isMulti
        isClearable
        placeholder={placeholder}
        value={item}
        onChange={on_group_change}
        options={items}
        styles={dropdowm.select_color_config}
      />
    </Form.Field>
  );
};

MultiSelect.propTypes = {
  selector: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  params: PropTypes.shape(PropTypes.any).isRequired,
  _ids: PropTypes.arrayOf(PropTypes.any),
  on_select: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};

MultiSelect.defaultProps = {
  _ids: [],
};

export { MultiSelect };
