import React from 'react';
import PropTypes from 'prop-types';

import './CartSummaryTitle.scss';

const CartSummaryTitle = ({ customer }) => (
  <div className="cart-summary-title">
    <h1>
      { customer ? `${customer.customer_index} - ${customer.customer_name}` : 'Sin cliente' }
    </h1>
  </div>
);

CartSummaryTitle.propTypes = {
  customer: PropTypes.any.isRequired,
};

export { CartSummaryTitle };
