const enviroment = process.env.NODE_ENV || 'production';
const ip = '192.168.56.102';
const domain = 'unboliviano';

const BASE_PATH = {
  development: {
    ACCOUNTS: `http://${ip}:10101`,
    BILLINGS: `http://${ip}:10101`,
    FILES: `http://${ip}:10201`,
    USERS: `http://${ip}:10301`,
    SOCKET: `http://${ip}:10401`,

    SALES: `http://${ip}:10012`,
    MONITOR: `http://${ip}:10013`,
    EVENTS: `http://${ip}:10014`,

    APP: `http://${ip}:10012`,
  },
  production: {
    ACCOUNTS: `https://accounts.${domain}.com`,
    BILLINGS: `https://accounts.${domain}.com`,
    FILES: `https://files.${domain}.com`,
    SOCKET: `https://socket.${domain}.com`,

    SALES: `https://sales.${domain}.com`,
    MONITOR: `https://monitor.${domain}.com`,
    EVENTS: `https://events.${domain}.com`,

    APP: `https://sales.${domain}.com`,
  },
}[enviroment];

export { BASE_PATH };
