import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Button } from 'semantic-ui-react';

import { toast } from 'generics/services/globals/v3';
import { get_by_id } from 'generics/api/sales/v0/acoounts.service';

const AccountItem = ({ account_id, cb }) => {
  const [item, setItem] = useState(null);

  const handleCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    cb(account_id);
  };

  useEffect(() => {
    get_by_id({
      _id: account_id,
    })
    .then(({ data }) => {
      setItem(data);
    })
    .catch(toast.api_danger);
  }, [account_id]);

  return (
    <List.Item>
      <List.Content floated="right">
        <Button onClick={handleCancel}>Eliminar</Button>
      </List.Content>
      <List.Icon name="users" size="large" verticalAlign="middle" />
      {
        item && (<List.Content>{item.email}</List.Content>)
      }
    </List.Item>
  );
};

AccountItem.propTypes = {
  account_id: PropTypes.string.isRequired,
  cb: PropTypes.func.isRequired,
};

export { AccountItem };
