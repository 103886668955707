export const types = {
  STORE_PRODUCTS_INCREASE: 'store-products-increase',
  STORE_PRODUCTS_SEARCH: 'store-products-search',
  STORE_PRODUCTS_QUERY: 'store-products-query',
  STORE_PRODUCTS_CREATE: 'store-products-create',
  STORE_PRODUCTS_EDIT: 'store-products-edit',
  STORE_PRODUCTS_REMOVE: 'store-products-remove',
};

const increase = (data) => ({
  type: types.STORE_PRODUCTS_INCREASE,
  data,
});

const search = (data) => ({
  type: types.STORE_PRODUCTS_SEARCH,
  data,
});

const query = (data) => ({
  type: types.STORE_PRODUCTS_QUERY,
  data,
});

const create = (data) => ({
  type: types.STORE_PRODUCTS_CREATE,
  data,
});

const edit = (data) => ({
  type: types.STORE_PRODUCTS_EDIT,
  data,
});

const remove = (data) => ({
  type: types.STORE_PRODUCTS_REMOVE,
  data,
});

export default { increase, search, query, create, edit, remove };
