import axios from 'axios';

import { BASE_PATH } from 'generics/constants';
import { AuthService } from './auth.service';

// supplier
const PATH = `${BASE_PATH.SALES}/v1/product-indexers`;

const query = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).get(PATH, { params });
};

const pagination = (params, config) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).get(`${PATH}/page/${config.page}/limit/${config.limit}`, { params });
};

const create = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).post(PATH, params);
};

const edit = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).put(`${PATH}/${params._id}`, params);
};

const remove = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).delete(`${PATH}/${params._id}`);
};

export { query, pagination, create, remove, edit };
