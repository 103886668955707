export const type = {
  ON_STARTED: 'on-started',
  ON_SUCCESS: 'on-success',
  ON_FAILURE: 'on-failure',
};

const start = (data) => ({
  type: type.ON_STARTED,
  data,
});

const success = (data) => ({
  type: type.ON_SUCCESS,
  data,
});

const failure = (data) => ({
  type: type.ON_FAILURE,
  data,
});

export { start, success, failure };
