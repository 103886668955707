import { GENERIC } from 'generics/constants';
import { Store } from 'react-notifications-component';

const success = (message) => {
  Store.addNotification({
    title: GENERIC.APP_SHORT_NAME,
    message,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 2000,
    },
  });
};

const danger = (message) => {
  Store.addNotification({
    title: GENERIC.APP_SHORT_NAME,
    message,
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 2000,
    },
  });
};

const warning = (message) => {
  Store.addNotification({
    title: GENERIC.APP_SHORT_NAME,
    message,
    type: 'warning',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 2000,
    },
  });
};

const api_danger = (err) => {
  if (err.response) {
    danger(err.response.data.message);
    return;
  }

  /* eslint-disable no-console */
  console.error(err);
};

export default { success, danger, warning, api_danger };
