import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import api from 'generics/api/billings/v1';
import { toast, StorageService } from 'generics/services/globals';
import { List } from './list/List';
import { CASH_TYPES, PAYMENT_TYPES, ORDER_TYPES } from '../sales.constants';

import './CheckoutConfig.scss';

const CheckoutConfig = ({ config, set_config }) => {
  const { booking_id } = useParams();
  const [coin_types] = useState(CASH_TYPES);
  const [order_types] = useState(ORDER_TYPES);
  const [payment_types] = useState(PAYMENT_TYPES);

  const [coin_type, setCoinType] = useState(
    coin_types.find((item) => item.key === config.coin_type),
  );
  const [order_type, setOrderType] = useState(
    order_types.find((item) => item.key === config.order_type),
  );
  const [payment_type, setPaymentType] = useState(
    payment_types.find((item) => item.key === config.payment_type),
  );

  const save_configuration = (item) => {
    StorageService.save('checkout-config', item);
    set_config({
      coin_type: item.coin_type.key,
      payment_type: item.payment_type.key,
      order_type: item.order_type.key,
    });
    api.bookings.update({
      _id: booking_id,
      coin_type: item.coin_type.key,
      payment_type: item.payment_type.key,
      order_type: item.order_type.key,
    })
    .catch(toast.api_danger);
  };

  const onCoinSelect = (item) => {
    setCoinType(item);
    save_configuration({
      coin_type: item,
      payment_type,
      order_type,
    });
  };

  const onOrderSelect = (item) => {
    setOrderType(item);
    save_configuration({
      coin_type,
      payment_type,
      order_type: item,
    });
  };

  const onPaymentSelect = (item) => {
    setPaymentType(item);
    save_configuration({
      coin_type,
      payment_type: item,
      order_type,
    });
  };

  return (
    <div className="checkout-config">
      <List current={coin_type} items={coin_types} onSelect={onCoinSelect} />
      <List current={order_type} items={order_types} onSelect={onOrderSelect} />
      <List current={payment_type} items={payment_types} onSelect={onPaymentSelect} />
    </div>
  );
};

CheckoutConfig.propTypes = {
  config: PropTypes.any.isRequired,
  set_config: PropTypes.func.isRequired,
};

export { CheckoutConfig };
