import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Flex, Container } from 'components/grids/v3';
import { VNavbar } from 'components/headers/v1';
import { SettingDetail } from './setting/SettingDetail';
import { SettingControlCode } from './setting/SettingControlCode';
import { SettingInvoice } from './setting/SettingInvoice';
import { SettingCompany } from './setting/SettingCompany';
import { SettingLogo } from './setting/SettingLogo';
import { SettingOrder } from './setting/SettingOrder';
import { SettingIndexer } from './setting/SettingIndexer';

const Settings = ({ area }) => {
  const match = useRouteMatch();
  const [link, set_link] = useState(null);
  const [links] = useState([
    { _id: '1', label: 'Información', icon: 'home', uri: `${match.url}/detail` },
    { _id: '2', label: 'Empresa', icon: 'edit', uri: `${match.url}/company` },
    { _id: '3', label: 'Factura', icon: 'edit', uri: `${match.url}/invoice` },
    { _id: '4', label: 'Control code', icon: 'edit', uri: `${match.url}/code` },
    { _id: '5', label: 'Ordenes', icon: 'edit', uri: `${match.url}/order` },
    { _id: '6', label: 'Numeración', icon: 'edit', uri: `${match.url}/indexer` },
    { _id: '7', label: 'Editar', icon: 'edit', uri: `${match.url}/logo` },
  ]);

  const on_select = useCallback((data) => {
    set_link(data);
  }, []);

  return (
    <Flex direction={Flex.V}>
      <Container width="1200px">
        <VNavbar link={link} links={links} on_select={on_select} />
        <Switch>
          <Route exact path={`${match.path}/detail`}>
            <SettingDetail area={area} />
          </Route>
          <Route exact path={`${match.path}/company`}>
            <SettingCompany area={area} />
          </Route>
          <Route exact path={`${match.path}/invoice`}>
            <SettingInvoice area={area} />
          </Route>
          <Route exact path={`${match.path}/code`}>
            <SettingControlCode area={area} />
          </Route>
          <Route exact path={`${match.path}/order`}>
            <SettingOrder area={area} />
          </Route>
          <Route exact path={`${match.path}/indexer`}>
            <SettingIndexer area={area} />
          </Route>
          <Route exact path={`${match.path}/logo`}>
            <SettingLogo area={area} />
          </Route>
        </Switch>
      </Container>
    </Flex>
  );
};

Settings.propTypes = {
  area: PropTypes.any.isRequired,
};

export { Settings };
