import React from 'react';

import './None.scss';

const None = () => (
  <div className="print-none-invoice">
    <h1>SIN COMPROBANTE</h1>
    <ul>
      <li>Para imprimir un comprobante tiene selectionar un metodo de comprobante</li>
      <ul>
        <li>Recibo</li>
        <li>Factura</li>
        <li>Ninguno (actualmente)</li>
      </ul>
    </ul>
  </div>
);

export { None };
