export const types = {
  STOCKS_SEARCH: 'stock-search',
  STOCKS_STORE: 'stock-store',
};

const set_stock_search = (data) => ({
  type: types.STOCKS_SEARCH,
  data,
});

const set_stock_store = (data) => ({
  type: types.STOCKS_STORE,
  data,
});

export default { set_stock_search, set_stock_store };
