import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { List } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import './VNavbar.scss';

const VNavbar = ({ link, links, on_select }) => {
  const history = useHistory();
  const handle_select = (e, item) => {
    e.stopPropagation();
    on_select(item);
    history.push(item.uri);
  };

  return (
    <div className="navbar-list">
      {
        links.map((item) => (
          <div
            key={item._id}
            className={classnames('navbar-item', { 'on-select active': link && link._id === item._id })}
            onClick={(e) => handle_select(e, item)}
          >
            <List.Icon name={item.icon} size="large" />
            <div className="icon-label">{item.label}</div>
          </div>
        ))
      }
    </div>
  );
};

VNavbar.propTypes = {
  link: PropTypes.shape({
    _id: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    uri: PropTypes.string,
  }),
  links: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    uri: PropTypes.string,
  })).isRequired,
  on_select: PropTypes.func.isRequired,
};

VNavbar.defaultProps = {
  link: null,
};

export { VNavbar };
