import axios from 'axios';

import { BASE_PATH } from 'generics/constants';
import { AuthService } from '../auth.service';

const PATH = `${BASE_PATH.USERS}/v1/users`;
const PATH_P1 = `${BASE_PATH.USERS}/p1/users`;

const find_by_id = (_id) => {
  if (!_id) {
    throw new Error('_id is undefined');
  }

  return axios.create(AuthService.header()).get(`${PATH}/${_id}`);
};

const find_customer = (pagination, params) => {
  if (!pagination) {
    throw new Error('pagination is undefined');
  }
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).get(`${PATH}/customers/page/${pagination.page}/limit/${pagination.limit}`, { params })
  .catch(({ response }) => {
    throw response.data;
  });
};

const query = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).get(PATH, { params })
  .catch(({ response }) => {
    throw response.data;
  });
};

const pagination = (config, params) => {
  // eslint-disable-next-line
  console.log('FIXME: DEPRECATED');

  if (!config) {
    throw new Error('config is undefined');
  }
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).get(`${PATH}/page/${config.page}/limit/${config.limit}`, { params });
};

const create = (params, payload) => {
  if (!params) {
    throw new Error('params is undefined');
  }
  if (!payload) {
    throw new Error('payload is undefined');
  }

  return axios.create(AuthService.header()).post(PATH, payload);
};

const create_customer = (params, payload) => {
  if (!payload) {
    throw new Error('payload is undefined');
  }

  return axios.create(AuthService.header()).post(`${PATH_P1}/customer`, payload, {
    params,
  });
};

const edit = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).put(`${PATH}/${params._id}`, params);
};

const remove = (params) => {
  if (!params) {
    throw new Error('params is undefined');
  }

  return axios.create(AuthService.header()).delete(`${PATH}/${params._id}`);
};

export { find_by_id, find_customer, query, pagination, create, create_customer, remove, edit };
