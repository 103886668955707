import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'generics/services/globals/v4';
import service from 'generics/api/sales/v2';
import actions from 'store/actions/kiosk';

import { ApeKioskCardList } from './list/ApeKioskCardList';

import './Shops.scss';

const Shops = ({ account, area }) => {
  const { sales_point } = useSelector((state) => state.kiosk);
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();

  const on_select = useCallback((item) => {
    dispatch(actions.kiosk.store_shop(item));
  }, [dispatch]);

  const convert = (item) => ({
    image: null,
    title: item.name || 'Not found',
    description: item.description || 'Not found',
  });

  useEffect(() => {
    if (!area) {
      return;
    }

    service.sales_points.find({ area_id: area._id, accounts: account._id })
    .then(({ data }) => {
      setItems([...data]);
    })
    .catch(toast.api_danger);
  }, [area, account]);

  return (
    <div className="select-current-shop">
      <div className="current-shops-list">
        {
          items.length
            ? (
              <ApeKioskCardList
                current={sales_point}
                items={items}
                onSelect={on_select}
                convert={convert}
              />
            )
            : <h2>Aun no tienes un punto de venta configurado</h2>
        }
      </div>
    </div>
  );
};

Shops.propTypes = {
  account: PropTypes.any.isRequired,
  area: PropTypes.any.isRequired,
};

export { Shops };
