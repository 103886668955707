import { BASE_PATH } from 'generics/constants';
import { common } from 'generics/services/globals/v4';

const PATH_V1 = `${BASE_PATH.SALES}/v1/store-products`;
const PATH_V2 = `${BASE_PATH.SALES}/v2/store-products`;

const get_by_id = common.get_by_id(PATH_V2);
const find = common.find(PATH_V2);
const find_by = common.find_by(PATH_V1);
const create = common.create(PATH_V1);
const update = common.update(PATH_V1);
const remove = common.remove(PATH_V1);

const find_v1 = common.find(PATH_V1);

const increase = (_id, params) => (
  common.get_session().post(`${PATH_V2}/${_id}/amount`, params)
);

export default {
  get_by_id,
  find,
  find_by,
  create,
  remove,
  update,

  find_v1,
  increase,
};
