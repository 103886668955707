import React, { useCallback, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { VContainer, VFlex, CContainer } from 'components';
import { VNavbar } from 'components/headers/v1';
import { HomeAreaUpdate } from './areas/HomeAreaUpdate';
import { HomeArea } from './areas/HomeArea';
import { Areas } from './areas/Areas';
import { AreaDetail } from './areas/AreaDetail';
import { AreaUpdate } from './areas/AreaUpdate';
import { AreaGroups } from './groups/AreaGroups';
import { AreaGroupDetail } from './groups/AreaGroupDetail';
import { AreaGroupUpdate } from './groups/AreaGroupUpdate';

import './Configuration.scss';

const Configuration = () => {
  const match = useRouteMatch();
  const { area } = useSelector((state) => state.apps);
  const [link, setLink] = useState(null);
  const [links] = useState([
    { _id: '1', label: 'Inicio', icon: 'home', uri: `${match.url}/detail` },
    { _id: '2', label: 'Areas', icon: 'circle', uri: `${match.url}/areas` },
    { _id: '3', label: 'Grupos', icon: 'group', uri: `${match.url}/groups` },
    { _id: '4', label: 'Editar', icon: 'edit', uri: `${match.url}/update` },
  ]);

  const on_select = useCallback((data) => {
    setLink(data);
  }, []);

  return (
    <CContainer>
      <div className="configuration">
        <VContainer>
          <VNavbar link={link} links={links} on_select={on_select} />
          <VFlex flex={1}>
            <Switch>
              <Route exact path={`${match.path}/detail`}>
                <HomeArea area={area} />
              </Route>
              <Route exact path={`${match.path}/areas`}>
                <Areas area={area} />
              </Route>
              <Route exact path={`${match.path}/areas/:area_id`}>
                <AreaDetail area={area} />
              </Route>
              <Route exact path={`${match.path}/areas/:area_id/update`}>
                <AreaUpdate area={area} />
              </Route>
              <Route exact path={`${match.path}/groups`}>
                <AreaGroups area={area} />
              </Route>
              <Route exact path={`${match.path}/groups/:group_id`}>
                <AreaGroupDetail area={area} />
              </Route>
              <Route exact path={`${match.path}/groups/:group_id/update`}>
                <AreaGroupUpdate area={area} />
              </Route>
              <Route exact path={`${match.path}/update`}>
                <HomeAreaUpdate area={area} />
              </Route>
            </Switch>
          </VFlex>
        </VContainer>
      </div>
    </CContainer>
  );
};

export { Configuration };
