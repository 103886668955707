import React from 'react';
import PropTypes from 'prop-types';

import { WLinearListOption } from 'components/lists/v1';
import { Administrator } from './Administrator';

const Administrators = ({ items, on_remove }) => {
  const render_item = (item) => (
    <Administrator key={item._id} item={item} on_remove={on_remove} />
  );

  return (
    <WLinearListOption>
      {items.map((item) => render_item(item))}
    </WLinearListOption>
  );
};

Administrators.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  on_remove: PropTypes.func.isRequired,
};

export { Administrators };
