import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form } from 'semantic-ui-react';

import actions from 'store/actions/globals/v1';
import api from 'generics/api/globals/v1';
import { useFormReducer } from 'generics/functions/globals/reducer';
import { toast } from 'generics/services/globals/v4';

import { IButton, UImage, ContentTitle } from 'components';
import { AREA_UPDATE_TITLE } from '../account.constants';

const AccountUpdate = ({ account }) => {
  const dispatch = useDispatch();
  const {
    item,
    on_string_change,
    on_image_change,
  } = useFormReducer({
    _id: account._id,
    username: account.username,
    first_name: account.first_name,
    last_name: account.last_name,
    image_id: account.image_id,
  });

  const handle_submit = (e) => {
    e.preventDefault();
    api.accounts.update(item)
    .then(({ data }) => {
      dispatch(actions.accounts.set(data));
      toast.success('se actualizo correctamente');
    })
    .catch(toast.api_danger);
  };

  return (
    <>
      <ContentTitle config={AREA_UPDATE_TITLE} />
      <Form onSubmit={handle_submit} inverted>
        <Form.Field>
          <label>Usuario</label>
          <input name="username" placeholder="Usuario" value={item.username} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Apellido</label>
          <input name="last_name" placeholder="Apellido" value={item.last_name} onChange={on_string_change} />
        </Form.Field>
        <Form.Field>
          <label>Nombre</label>
          <input name="first_name" placeholder="Nombre" value={item.first_name} onChange={on_string_change} />
        </Form.Field>
        <UImage image={item.image_id} height="150px" quality={1080} on_callback={on_image_change} />
        <IButton label="Actualizar información" icon="checkmark" submit />
      </Form>
    </>
  );
};

AccountUpdate.propTypes = {
  account: PropTypes.any.isRequired,
};

export { AccountUpdate };
