import React from 'react';

import { BACKGROUND } from 'generics/constants';
import { Screen } from 'components/screens/v2';

const CheckoutHistory = () => (
  <Screen background={BACKGROUND}>
    generator
  </Screen>
);

export { CheckoutHistory };
