import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Flex } from 'components/grids/v3';
import { Button } from 'components/buttons/v2';

const ListItem = ({ item, on_select }) => {
  const on_click = () => {
    on_select(item);
  };

  const render_payment_state = (payment) => {
    if (payment) {
      return <Button label="Pagado" color="green" icon="clock outline" on_click={on_click} />;
    }

    return <Button label="Cerrado" color="red" icon="clock outline" on_click={() => {}} />;
  };

  return (
    <Flex.Scrap class_name="report-list-item">
      <Flex direction={Flex.H}>
        <Flex.Item flex={1}>
          {item.index}
        </Flex.Item>
        <Flex.Item flex={3}>
          {moment(item.completed).format('MM/DD/YYYY LT')}
        </Flex.Item>
        <Flex.Item flex={3}>
          {item.account_name}
        </Flex.Item>
        <Flex.Item flex={3}>
          {item.customer_name}
        </Flex.Item>
        <Flex.Item flex={5}>
          {item.description}
        </Flex.Item>
        <Flex.Item flex={2}>
          {`${item.price} Bs.`}
        </Flex.Item>
        <Flex.Item flex={2}>
          {render_payment_state(item.payment)}
        </Flex.Item>
      </Flex>
    </Flex.Scrap>
  );
};

ListItem.propTypes = {
  item: PropTypes.any.isRequired,
  on_select: PropTypes.func.isRequired,
};

export { ListItem };
