import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'components';
import { HToolbarButton, HToolbarTitle, HToolbarFlex } from '../container/HToolbarContainer';

const HeaderLogout = () => {
  const history = useHistory();

  const on_home = () => {
    history.push('/');
  };

  const on_register = () => {
    history.push('/register');
  };

  const on_login = () => {
    history.push('/login');
  };

  return (
    <>
      <HToolbarTitle>
        <div aria-hidden="true" onClick={on_home}>
          Un Boliviano
        </div>
      </HToolbarTitle>
      <HToolbarFlex />
      <HToolbarButton>
        <Button label="Uneté" color="teal" onClick={on_register} />
        <Button label="Iniciar session" onClick={on_login} />
      </HToolbarButton>
    </>
  );
};

export { HeaderLogout };
