import { StorageService } from './storage.service';

const get_token = () => {
  const token = StorageService.load('token');
  return `Bearer ${token.session_id}`;
};

const header = () => {
  const token = StorageService.load('token');
  return {
    headers: {
      Authorization: token ? get_token() : null,
    },
  };
};

const headers = () => {
  const token = StorageService.load('token');
  return {
    Authorization: token ? get_token() : null,
  };
};

// TODO: remove class
class AuthService {
  static header() {
    const token = StorageService.load('token');
    return {
      headers: {
        Authorization: token ? `Bearer ${token.session_id}` : null,
      },
    };
  }

  static headers() {
    const token = StorageService.load('token');
    return {
      Authorization: token ? `Bearer ${token.session_id}` : null,
    };
  }
}

export { header, headers, get_token, AuthService };
