export const types = {
  KIOSK_CATEGORY_SET: 'kiosk-category-set',
  KIOSK_CATEGORY_SET_ONE: 'kiosk-category-set-one',
  KIOSK_CATEGORY_UPDATE: 'kiosk-category-update',
  KIOSK_CATEGORY_REMOVE: 'kiosk-category-remove',
};

const set = (data) => ({
  type: types.KIOSK_CATEGORY_SET,
  data,
});

const set_one = (data) => ({
  type: types.KIOSK_CATEGORY_SET_ONE,
  data,
});

const update = (data) => ({
  type: types.KIOSK_CATEGORY_UPDATE,
  data,
});

const remove = (data) => ({
  type: types.KIOSK_CATEGORY_REMOVE,
  data,
});

export default { set, set_one, update, remove };
