import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { Input, Form } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

import { Header, EmptyInfo } from 'components/titles/v2';
import { GENERIC } from 'generics/constants';
import api from 'generics/api/billings/v2';
import { toast } from 'generics/services/globals/v4';
import { Flex } from 'components/grids/v2';
import { useFormReducer } from 'generics/functions/globals/reducer';
import { RemoveConfirmation } from 'components/generics/v1';
import { IButton } from 'components/buttons/v2';
import { List } from './terminate_list/List';

const TerminatesEdit = ({ area }) => {
  const { account } = useSelector((state) => state.auth);
  const { buy_id } = useParams();
  const history = useHistory();
  const {
    item,
    set_item,
    on_string_change,
  } = useFormReducer({
    name: 'Cerrar caja',
    start: null,
    end: null,
    sale_ids: [],
    buy_ids: [],
    buys_total: 0,
    sales_total: 0,
    account_id: account._id,
  });
  const [is_new] = useState(buy_id === undefined);
  const [range, set_range] = useState({
    start: moment().startOf('day').toDate(),
    end: moment().endOf('day').toDate(),
  });
  const [sale_items, set_sale_items] = useState([]);
  const [buy_items, set_buy_items] = useState([]);
  const [sales_total, set_sales_total] = useState(0);
  const [buys_total, set_buys_total] = useState(0);

  const on_submit = (e) => {
    e.preventDefault();
    if (is_new) {
      const new_item = {
        ...item,
        area_id: area._id,
        start: range.start,
        end: range.end,
        sale_ids: sale_items.map((data) => data._id, 0),
        buy_ids: buy_items.map((data) => data._id, 0),
        buys_total,
        sales_total,
      };
      api.terminates.create(new_item)
      .then(() => {
        history.push(`${GENERIC.APP_BASE_URL}/terminates`);
      })
      .catch(toast.api_danger);
    } else {
      api.terminates.update(item._id, item)
      .then(() => {
        history.push(`${GENERIC.APP_BASE_URL}/terminates`);
      })
      .catch(toast.api_danger);
    }
  };

  const on_search = useCallback((query) => {
    api.sales.terminate({ area_id: area._id, ...query })
    .then(({ data }) => {
      set_sale_items(data);
      set_sales_total(data.reduce((total, value) => total + value.total, 0));
    })
    .catch(toast.api_danger);
    api.buys.terminate({ area_id: area._id, ...query })
    .then(({ data }) => {
      set_buy_items(data);
      set_buys_total(data.reduce((total, value) => total + value.total, 0));
    })
    .catch(toast.api_danger);
  }, [area]);

  const on_update = () => {
    on_search(range);
  };

  const on_remove_callback = useCallback(() => {
    api.buys.remove(item)
    .then(() => {
      history.push(`${GENERIC.APP_BASE_URL}/buys`);
    })
    .catch(toast.api_danger);
  }, [history, item]);

  useEffect(() => {
    if (is_new) return;

    api.buys.get_by_id(buy_id)
    .then(({ data }) => {
      set_item(data);
    })
    .catch(toast.api_danger);
  }, [is_new, buy_id, area, set_item]);

  useEffect(() => {
    on_search(range);
  }, [range, on_search]);

  const render_dates = () => {
    const start = moment(range.start).format('YYYY-MM-DD');
    const end = moment(range.end).format('YYYY-MM-DD');

    const on_start_date_change = (element) => {
      set_range({ ...range, start: moment(element.target.value).startOf('day').toDate() });
    };

    const on_end_date_change = (element) => {
      set_range({ ...range, end: moment(element.target.value).endOf('day').toDate() });
    };

    return (
      <>
        <Input type="date" size="mini" value={start} onChange={on_start_date_change} />
        <Input type="date" size="mini" value={end} onChange={on_end_date_change} />
        <IButton label="Buscar" icon="search" on_click={on_update} />
      </>
    );
  };

  return (
    <>
      <Header title="Caja diaria" />
      <Form onSubmit={on_submit} inverted>
        <Form.Field>
          <label>Nombre</label>
          <input name="name" placeholder="Nombre" value={item.name} onChange={on_string_change} />
        </Form.Field>
        <Flex.End>
          {render_dates()}
        </Flex.End>
        <Header title={`Ventas ${sales_total} Bs.`} />
        <Flex.Item>
          {(sale_items.length === 0) && <EmptyInfo description="No existe ventas registradas" />}
          {(sale_items.length > 0) && <List items={sale_items} on_select={() => {}} />}
        </Flex.Item>
        <Header title={`Compras ${buys_total} Bs.`} />
        <Flex.Item>
          {(buy_items.length === 0) && <EmptyInfo description="No existe compras registradas" />}
          {(buy_items.length > 0) && <List items={buy_items} on_select={() => {}} />}
        </Flex.Item>
        <Flex.End>
          <Header title={`${sales_total} - ${buys_total} = ${sales_total - buys_total}Bs.`} />
        </Flex.End>
        <Flex.End>
          {!is_new && <RemoveConfirmation label="Eliminar caja" on_click={on_remove_callback} />}
          {is_new && <IButton label="Cerrar caja" icon="checkmark" submit />}
          {!is_new && <IButton label="Actualizar caja" icon="checkmark" submit />}
        </Flex.End>
      </Form>
    </>
  );
};

TerminatesEdit.propTypes = {
  area: PropTypes.any.isRequired,
};

export { TerminatesEdit };
