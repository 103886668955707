export const AREA_UPDATE_TITLE = {
  context: 'account',
  title: 'editar cuenta',
  description: 'generator',
};

export const AREA_DETAIL_TITLE = {
  context: 'account',
  title: 'configuración de la cuenta',
  description: 'generator',
};
