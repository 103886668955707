import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './List.scss';

const ListItem = ({ current, item, onSelect }) => {
  const onClick = (e) => {
    e.stopPropagation();
    onSelect(item);
  };

  return (
    <div className={classnames('checkout-list-item', { 'checkout-list-item-select': current && current.key === item.key })} onClick={onClick}>
      {item.value}
    </div>
  );
};

ListItem.propTypes = {
  current: PropTypes.any,
  item: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
};

ListItem.defaultProps = {
  current: null,
};

export { ListItem };
