import accounts from './accounts.service';
import auth from './auth.service';
import areas from './areas.service';
import groups from './groups.service';

export default {
  auth,
  accounts,
  areas,
  groups,
};
