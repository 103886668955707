import React, { useState } from 'react';

import { StoreProductList } from './StoreProductList';
import { ContentContainer } from '../../container';
import { WebTitle } from '../../components/WebTitle';

const StoreProduct = () => {
  const [header] = useState({
    context: 'ADMINISTRATOR',
    title: 'Inventario',
    subtitle: 'Centro de almacenaje',
  });

  return (
    <ContentContainer>
      <WebTitle config={header} />
      <StoreProductList />
    </ContentContainer>
  );
};

export { StoreProduct };
