import { BASE_PATH } from 'generics/constants';
import { common } from '../../../services/globals';

const PATH_V1 = `${BASE_PATH.BILLINGS}/v1/bookings`;
const PATH_V2 = `${BASE_PATH.BILLINGS}/v2/bookings`;

const get_by_id = common.get_by_id(PATH_V1);
const find = common.find(PATH_V1);
const find_by = common.find_by(PATH_V1);
const create = common.create(PATH_V2);
const update = common.update(PATH_V1);
const remove = common.remove(PATH_V1);

const report = common.find(`${PATH_V2}/reports`);
const checkout = (_id, payload) => (
  common.get_session().put(`${PATH_V2}/${_id}/checkout`, payload)
);

export default { get_by_id, find, find_by, create, remove, update, report, checkout };
