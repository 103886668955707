import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { BACKGROUND } from 'generics/constants';
import { Session } from '../session/Session';

import { Menu } from './Menu';
import { Kiosk } from './kiosk/Kiosk';
import { Configuration } from './configuration/Configuration';
import { CheckoutHistory } from './checkout-history/CheckoutHistory';
import { Screen, Container, IconToolbarContainer } from '../container';
import { GRouter as BillingsRouter } from './kiosk/billings/GRouter';

const Market = () => {
  const [item, set_item] = useState(null);
  const { path } = useRouteMatch();
  const { area } = useSelector((state) => state.apps);

  useEffect(() => {
    set_item(area);
  }, [area]);

  const render_markets = () => (
    <Switch>
      <Route exact path={`${path}/kiosk`}>
        <Kiosk area={item} />
      </Route>
      <Route path={`${path}/sales`}>
        <BillingsRouter area={item} />
      </Route>
      <Route exact path={`${path}/configuration`}>
        <Configuration />
      </Route>
      <Route exact path={`${path}/history`}>
        <CheckoutHistory />
      </Route>
    </Switch>
  );

  return (
    <Screen background={BACKGROUND}>
      <IconToolbarContainer>
        <Session />
        <Menu />
      </IconToolbarContainer>
      <Container>
        {area && render_markets()}
      </Container>
    </Screen>
  );
};

export { Market };
