import React from 'react';

import { VContainer } from '../components';

const Search = () => {
  return (
    //#5d1744
    <VContainer>
      Buscador
    </VContainer>
  );
};

export { Search };
