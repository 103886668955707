import * as loadingActions from './loading';

const generator = (actions, method, item, isLoading = true) => (dispatch) => {
  if (!actions.service) {
    throw new Error(`${actions} service is undefined`);
  }
  const func = actions.service[method];
  const action = actions[method];
  if (!func) {
    throw new Error(`function ${method} is undefined`);
  }
  if (!action) {
    throw new Error(`action ${method} is undefined`);
  }
  if (isLoading) {
    dispatch(loadingActions.start());
  }

  return func(item)
  .then(({ data }) => {
    dispatch(action(data));
    if (isLoading) {
      dispatch(loadingActions.success());
    }
  })
  .catch((err) => {
    if (isLoading) {
      dispatch(loadingActions.failure(err.response.data));
    }
  });
};

export { generator };
