export const AREA_GROUPS_TITLE = {
  context: 'administrador',
  title: 'lista de grupos',
  description: 'administra los grupos el nivel mas alto de la organización',
};

export const AREA_GROUPS_UPDATE_TITLE = {
  context: 'administrador',
  title: 'actualizar grupos',
  description: 'administra los grupos el nivel mas alto de la organización',
};
