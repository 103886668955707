import categories from './categories.actions';
import kiosk from './kiosk.actions';
import store_products from './store_products.actions';
import toolbar from './toolbar.actions';

export default {
  categories,
  kiosk,
  store_products,
  toolbar,
};
