import { types } from 'store/actions/kiosk/categories.actions';

const initial_state = {
  categories: [],
};

const reducer = (state = initial_state, { type, data }) => {
  switch (type) {
  case types.KIOSK_CATEGORY_SET: {
    return { ...state, categories: [...data] };
  }
  case types.KIOSK_CATEGORY_SET_ONE: {
    return { ...state, categories: [data, ...state.categories] };
  }
  case types.KIOSK_CATEGORY_UPDATE: {
    const categories = state.categories.map((item) => (item._id === data._id ? { ...data } : item));
    return { ...state, categories };
  }
  case types.KIOSK_CATEGORY_REMOVE: {
    const categories = state.categories.filter((item) => item._id !== data._id);
    return { ...state, categories };
  }
  default:
    return state;
  }
};

export default reducer;
