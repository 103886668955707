import { StorageService } from 'generics/services/globals';
import { types } from 'store/actions/kiosk/kiosk.actions';

import { ACTIONS } from '../utils/kiosk-constants';
import { save_cart, load_cart } from '../utils/kiosk';

const DEFAULT_AMOUNT = 1;
const order = StorageService.load('order') || { key: 1, value: 1 };
const initialState = {
  sales_point: StorageService.load('sales-point') || null,
  app: null,
  order,
  orders: StorageService.load('orders') || [order],
  cartItem: null,
  cart: StorageService.load(`cart-${order.key}`) || [],
  client: {
    is_open: false,
    user: null,
  },
};

const reducer = (state = initialState, { type, data }) => {
  switch (type) {
  case types.STORE_SHOP:
    StorageService.save('sales-point', data);
    return {
      ...state,
      sales_point: data,
    };
  case types.ADD_TO_CART: {
    const cartItem = state.cart.find((item) => item._id === data._id);
    if (cartItem) {
      cartItem.sale.amount = cartItem.sale.amount + 1;
      // cartItem.sale.total = cartItem.sale.amount * cartItem.sale.price;
      save_cart(state.order, state.cart);
      return { ...state };
    }

    const { product, store_product } = data;
    const sale = {
      product_id: product._id,
      store_product_id: store_product._id,
      price_original: store_product.price,
      price_sale: store_product.price,
      amount: DEFAULT_AMOUNT,
    };
    state.cart = [...state.cart, { ...data, sale }];
    save_cart(state.order, state.cart);
    return { ...state };
  }
  case types.REMOVE_TO_CART: {
    state.cart = state.cart.filter((item) => item._id !== data._id);
    save_cart(state.order, state.cart);
    return { ...state };
  }
  case types.ADD_ORDER: {
    if (!state.orders.length) {
      state.orders.push({ key: 1, value: 1 });
    }
    const order = state.orders[state.orders.length - 1];
    state.orders.push({ key: order.key + 1, value: order.value + 1 });
    StorageService.save('order', state.order);
    StorageService.save('orders', state.orders);
    return { ...state };
  }
  case types.REMOVE_ORDER: {
    state.orders.pop();
    StorageService.save('order', state.order);
    StorageService.save('orders', state.orders);
    return { ...state };
  }
  case types.SELECT_ORDER: {
    StorageService.save('order', data);
    return {
      ...state,
      order: data,
      cartItem: null,
      cart: load_cart(data),
    };
  }
  case types.SELECT_CART_ITEM: {
    state.cartItem = data;
    return { ...state };
  }
  case types.CLEAR_CART_ITEM: {
    state.cartItem = data;
    return { ...state };
  }
  case types.PAY_ACTION: {
    if (state.cartItem) {
      const sale = state.cartItem.sale;
      if (data.action === ACTIONS.QUANTITY) {
        sale.amount = data.amount;
      }
      if (data.action === ACTIONS.PRICE) {
        sale.price_sale = data.amount;
      }
    }
    save_cart(state.order, state.cart);
    return { ...state };
  }
  case types.CLEAN_CART: {
    state.cartItem = null;
    state.cart = [];
    save_cart(state.order, state.cart);
    return { ...state };
  }
  case types.OPEN_CLIENT_DIALOG: {
    const client = { ...state.client, ...data }
    return { ...state, client };
  }

  default:
    return state;
  }
};

export default reducer;
