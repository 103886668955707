import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'components/grids/v2';

import './ContentTitle.scss';

const ContentTitle = ({ config }) => (
  <Flex.Scrap class_name="content-title">
    {
      config && (
        <>
          <h5>{config.context}</h5>
          <h1>{config.title}</h1>
          <p>{config.description}</p>
        </>
      )
    }
  </Flex.Scrap>
);

ContentTitle.propTypes = {
  config: PropTypes.shape({
    context: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export { ContentTitle };
